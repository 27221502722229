<template>
  <div class="l-container">
    <div class="assumption-rating">
      <div class="assumption-rating__counter">
        <strong>{{ assumption_nr }}</strong>/{{ totalNumber }} <strong>{{ topic }}</strong>
      </div>
      <blockquote class="assumption-rating__quote">{{text}}</blockquote>
      <details v-if="textAdditional" class="assumption-rating__details" v-on:click="this.$matomo?.trackEvent('Assumption rating', `Open/close details`, 'Assumption');">
        <summary>Hintergründe zur These</summary>
        <p>{{ textAdditional }}</p>
      </details>
      <div class="assumption-rating__actions">
        <div class="assumption-rating__actions__primary">
          <button class="button button--uppercase button--large" data-rating="disagree" v-on:click="save_rating"><i class="aw-icon-close"></i> Lehne ab</button>
          <button class="button button--uppercase button--large" data-rating="agree" v-on:click="save_rating"><i class="aw-icon-check"></i> Stimme zu</button>
        </div>
        <div class="assumption-rating__actions__secondary">
          <button class="button button--small button--uppercase button--text" data-rating="neutral" v-on:click="save_rating">Neutral</button>
        </div>
      </div>

      <div class="assumption-rating__pagination">
        <div v-bind:class="['assumption-rating__pagination__item', { active: index <= assumption_nr }]" v-for="index in totalNumber" :key="index">
          &nbsp; <span v-if="index == assumption_nr" >{{ percentage }}</span>
        </div>
      </div>


      <ParliamentContext
          :parliamentLabel="parliament.label"
          :parliamentYear="parliament_election_year"
      />
    </div>
  </div>
</template>


<script>
import { useKCStore } from '@/app/stores/kc';
import { Parliaments } from "@/app/parliaments";
import ParliamentContext from "@/components/ParliamentContext";

let parliaments = new Parliaments();

export default {
  name: 'AssumptionRating',
  components: {
    ParliamentContext
  },
  props: ['parliament_route_value', 'assumption_nr'],
  computed: {
    text: function() {
      const store = useKCStore();
      let assumption = store.getAssumption(Number(this.assumption_nr));
      if (assumption !== null && assumption.text !== null) {
        return assumption.text;
      }
      return '';
    },
    textAdditional: function() {
      const store = useKCStore();
      let assumption = store.getAssumption(Number(this.assumption_nr));
      if (assumption !== null && assumption.text_additional !== null) {
        return assumption.text_additional;
      }
      return '';
    },
    totalNumber: function() {
      const store = useKCStore();
      return store.getAssumptionsCount();
    },
    topic: function() {
      const store = useKCStore();
      let assumption = store.getAssumption(Number(this.assumption_nr));
      if (assumption !== null && assumption.topic !== null) {
        return assumption.topic;
      }
      return '';
    },
    percentage: function() {
      let percentage = Math.round(this.assumption_nr * 100 / this.totalNumber);
      if (percentage >= 1) {
        return percentage + ' %';
      }
      return '';
    },
    parliament() {
      return parliaments.getParliamentByRouteValue(this.parliament_route_value);
    },
    parliament_election_year() {
      const store = useKCStore();
      return store.getElectionYear();
    }
  },
  updated() {
    // Blur clicked element when new assumption is laoded
    document.activeElement.blur();
  },
  mounted() {
    this.set_header_variant();

    let body = document.querySelector('html');
    body.classList.remove('is-open-modal');
  },
  methods: {
    save_rating(event) {
      const store = useKCStore();
      let rating = event.currentTarget.getAttribute('data-rating'),
          assumption_nr = Number(this.assumption_nr),
          payload = {
            assumption_nr: assumption_nr,
            rating: rating
          };
      // Set user position if needed.
      if (payload.assumption_nr !== null &&
          payload.rating !== null) {
        // create the user_positions array when it does not exist
        if (typeof store.played_candidacy_check.user_positions === 'undefined' ||
            store.played_candidacy_check.user_positions === null) {
          store.played_candidacy_check.user_positions = [
            payload
          ]
        }
        else {
          // update existing user_positions
          let existing_position_for_assumption = store.played_candidacy_check.user_positions.find(element => element.assumption_nr === payload.assumption_nr);
          if (existing_position_for_assumption) {
            existing_position_for_assumption = payload;
            let existing_position_key = store.played_candidacy_check.user_positions.findIndex(element => element.assumption_nr === payload.assumption_nr);
            store.played_candidacy_check.user_positions[existing_position_key] = existing_position_for_assumption;
          }
          else {
            store.played_candidacy_check.user_positions.push(payload);
          }
        }
      }
      // when the assumption is the last we redirect to the result page
      // otherwise we go to the next assumption
      let next_assumption_nr = assumption_nr +1,
          next_assumption = store.getAssumption(next_assumption_nr);
      if (next_assumption !== null) {
        this.$router.push({ name: "AssumptionRating",  params: { parliament_route_value: this.parliament_route_value,
          assumption_nr: String(next_assumption_nr)}})
      }
      else {
        // reset the result
        store.played_candidacy_check.result = null;
        this.$router.push({ name: "AreaCodeSearch",  params: { parliament_route_value: this.parliament_route_value}})
      }
    },
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.add('header--small');
    }

  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/assumption_rating";
@import "/src/scss/components/parliament_context";

</style>
