<template>
  <div class="result-footer">
    <div class="result-footer__logo">
      <a :href="getLink" target="_blank" class="result-footer__logo__link">
        <img alt="Logo von abgeordnetenwatch.de" src="@/assets/aw_logo.svg" width="250" height="25">
      </a>
    </div>
    <div class="result-footer__nav">
      <div class="result-footer__nav__label">Uns finden Sie auch auf</div>
      <div class="result-footer__nav__menu">
        <li class="result-footer__nav__menu__item">
          <a href="https://www.facebook.com/abgeordnetenwatch.de/" class="result-footer__nav__menu__link" target="_blank">Facebook</a>
        </li>
        <li class="result-footer__nav__menu__item">
          <a href="https://twitter.com/a_watch" class="result-footer__nav__menu__link" target="_blank">Twitter</a>
        </li>
        <li class="result-footer__nav__menu__item">
          <a href="https://www.instagram.com/abgeordnetenwatch/" class="result-footer__nav__menu__link" target="_blank">Instagram</a>
        </li>
        <li class="result-footer__nav__menu__item">
          <a href="https://www.youtube.com/user/abgeordnetenwatchDE" class="result-footer__nav__menu__link" target="_blank">Youtube</a>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {useKCStore} from "@/app/stores/kc";

export default {
  name: 'ResultFooter',
  props: ['parliament_route_value'],
  computed: {
    ...mapState(useKCStore,[
      'getParliamentLandingpageLink',
      'addTrackingParametersToLink'
    ]),
    getLink() {
      let link = this.getParliamentLandingpageLink();
      return this.addTrackingParametersToLink(link);
    }
  }
}
</script>