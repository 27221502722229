<template>
  <div class="l-container l-container--small">

    <ParliamentTitle
        :parliamentLabel="parliament.label"
        :parliamentYear="parliament_election_year"
        :parliamentIcon="parliament.icon"
    />
    <div class="area">
      <h2>Fast geschafft!</h2>
      <p class="h3">Geben Sie Ihre Postleitzahl ein und gelangen Sie zu Ihren Direktkandidierenden</p>
      <AreaCodeForm v-bind:parliament_period_id="parliament_period_id" v-bind:parliament_route_value="parliament_route_value" />
    </div>
  </div>
</template>

<script>
import { Parliaments } from '@/app/parliaments';
import AreaCodeForm from '../AreaCodeForm';
import ParliamentTitle from "@/components/ParliamentTitle";
import {useKCStore} from "@/app/stores/kc";
let parliaments = new Parliaments();

export default {
  name: 'AreaCodeSearch',
  props: ['parliament_route_value'],
  components: {
    ParliamentTitle,
    AreaCodeForm
  },
  methods: {
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.remove('header--small');
      header.classList.add('header--parliament-context');
    },
  },
  computed: {
    parliament() {
      return parliaments.getParliamentByRouteValue(this.parliament_route_value);
    },
    parliament_period_id() {
      const store = useKCStore();
      return store.getParliamentPeriodId();
    },
    parliament_election_year() {
      const store = useKCStore();
      return store.getElectionYear();
    }
  },
  mounted:function() {
    this.set_header_variant();
  }
}


</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/areacodesearch";

</style>
