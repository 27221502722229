<template>
  <div class="l-container">
    <h2 class="text-center">Wählen Sie aus diesen {{ parliamentConstituencyTerm }}en</h2>

    <div class="constituency-selection">
      <div class="constituency-selection__item" v-for="descriptor in getDescriptors()" v-bind:key="descriptor.descriptor">
        <descriptor v-bind:descriptor="descriptor.descriptor" v-bind:constituency_id="descriptor.constituency_id" v-bind:parliament_route_value="parliament_route_value"></descriptor>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useKCStore } from '@/app/stores/kc';
import Descriptor from '../Descriptor';
import {Parliaments} from "@/app/parliaments";

export default {
  name: 'ChooseDescriptors',
  components: {Descriptor},
  props: ['parliament_route_value'],
  computed: {
    parliamentConstituencyTerm() {
      const parliaments = new Parliaments();
      const parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value);
      return parliament.constituency_term;
    },
    ...mapState(useKCStore,[
      'getDescriptors',
    ])
  },
  mounted:function(){
    this.set_header_variant();
  },
  methods: {
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.remove('header--small');
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/constituency_selection";

</style>
