import axios from 'axios';

/**
 *
 * @param path The Api path.
 * @param store The pinia store to update.
 * @param stateProperty The store's state property to update with api response data.
 */
export function apiCall(path, store, stateProperty) {
  axios.get(path)
       .then((response) => {
           store[stateProperty] = response.data.data;
       }).catch(function (error) {
    console.log(error);
      store[stateProperty] = null;
  })
}