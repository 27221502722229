<template>
  <div class="l-container">
    <div class="result">
      <div v-if="loadingStatus === 'loading'">
        <AjaxProgress />
      </div>
      <div v-if="loadingStatus === 'loaded'">
        <ParliamentContext
            :parliamentLabel="parliament.label"
            :parliamentYear="parliament_election_year"
            :parliamentConstituencyTerm="parliament.constituency_term"
            :constituencyName="getConstituencyName()"
            :constituencyNumber="getConstituencyNumber()"
            variant="result"
        />
        <WinnersPodium :parliament_route_value="this.parliament_route_value" />
        <ResultEmailButton :parliament_route_value="this.parliament_route_value" />
        <ShareLinks :parliament_route_value="this.parliament_route_value" />
        <PoliticianOverview :parliament_route_value="this.parliament_route_value" />

        <AssumptionOverview :parliament_route_value="parliament_route_value" />

        <WebsiteTeaser :parliamentLabel="parliament.label" :parliament_route_value="parliament_route_value" />
        <NewsletterTeaser />
        <ResultFooter :parliament_route_value="parliament_route_value" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { Parliaments } from "@/app/parliaments";
import WinnersPodium from '../result-components/WinnersPodium';
import AssumptionOverview from '@/components/result-components/AssumptionOverview';
import ShareLinks from "@/components/result-components/ShareLinks";
import PoliticianOverview from "@/components/result-components/PoliticianOverview";
import WebsiteTeaser from "@/components/result-components/WebsiteTeaser";
import ResultFooter from "@/components/result-components/ResultFooter";
import NewsletterTeaser from "@/components/result-components/NewsletterTeaser";
import ParliamentContext from "@/components/ParliamentContext";
import {useKCStore} from "@/app/stores/kc";
import AjaxProgress from "@/components/AjaxProgress";
import ResultEmailButton from "@/components/result-components/ResultEmailButton";

let parliaments = new Parliaments();

export default {
  name: 'YourResult',
  props: ['parliament_route_value'],
  data: function() {
    return {
      'loadingStatus': 'loading'
    }
  },
  components: {
    ResultEmailButton,
    AjaxProgress,
    NewsletterTeaser,
    WebsiteTeaser,
    PoliticianOverview,
    AssumptionOverview,
    ShareLinks,
    WinnersPodium,
    ResultFooter,
    ParliamentContext
  },
  computed: {
    ...mapState(useKCStore, [
      'getConstituencyName',
      'getConstituencyNumber'
    ]),
    parliament() {
      return parliaments.getParliamentByRouteValue(this.parliament_route_value);
    },
    parliament_election_year() {
      const store = useKCStore();
      return store.getElectionYear();
    }
  },
  mounted:function(){
    this.set_header_variant();
    this.loadData();

    let body = document.querySelector('html');
    body.classList.remove('is-open-modal');
  },
  methods: {
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.add('header--small');
      header.classList.remove('header--parliament-context');
    },
    loadData() {
      // check if result was already loaded
      const store = useKCStore();
      let result = store.getResult();
      if (result !== null) {
        this.loadingStatus = 'loaded';
      }
      else {
        const store = useKCStore();
        // load the data
        let constituency_id = store.getConstituencyId(),
            candidacy_check_id = store.getPlayedCandidacyCheckId();
        store.loadResult({parliament_period_id: candidacy_check_id, constituency_id})
             .then(() => {
               this.loadingStatus = 'loaded';
             }).catch(function(error) {
          console.log(error);
          this.loadingStatus = 'error';
        });
      }
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/result";

</style>
