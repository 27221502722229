<template>
  <div class="assumption-compare">
    <h2 class="text-center">Sie und die Kandidierenden im Thesen-Vergleich</h2>
    <AssumptionSelect v-on:assumptionSet="setAssumptionEventHandler" :default_assumption="getAssumption()" />
    <AssumptionRatingTabs v-on:ratingSet="setFilterRatingEventHandler" :assumption="getAssumption()" :rating_selected="getRating()"  :parliament_route_value="parliament_route_value" :user_rating="getUserRating()" />
  </div>
</template>

<script>
import AssumptionSelect from '@/components/result-components/AssumptionSelect';
import AssumptionRatingTabs from '@/components/result-components/AssumptionRatingTabs';
import {useKCStore} from "@/app/stores/kc";

export default {
  name: "AssumptionOverview",
  props: ['parliament_route_value'],
  components: {
    AssumptionSelect,
    AssumptionRatingTabs
  },
  data() {
    return {
      'rating': null,
      'assumption_number': 1
    }
  },
  methods: {
    setFilterRatingEventHandler(rating) {
      this.rating = rating;
      this.getCandidatesPositions(rating);
    },
    setAssumptionEventHandler(assumption_nr) {
      this.assumption_number = assumption_nr;
      this.getCandidatesPositions(assumption_nr);
    },
    getCandidatesPositions(rating) {
      const store = useKCStore();
      return store.getFilteredCandidatesPositions(this.assumption_number, rating);
    },
    getAssumption() {
      const store = useKCStore();
      return store.getAssumption(this.assumption_number);
    },
    getRating() {
      if (this.rating !== null) {
        return this.rating;
      }
      return this.getUserRating();
    },
    getUserRating() {
      const store = useKCStore();
      let userPosition = store.getUserPositionByAssumptionNr(this.assumption_number);
      return userPosition.rating;
    }
  }
}
</script>

<style scoped>

</style>
