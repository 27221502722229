<template>
  <div class="header">
    <div class="l-container">
      <router-link :to="setLogoLink">
        <img alt="Logo von Kandidierendencheck ein Service von abgeordnetenwatch.de" src="@/assets/kc_logo.svg">
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderDefault',
  computed: {
    setLogoLink() {
      if (this.$route.params.parliament_route_value) {
        return '/' + this.$route.params.parliament_route_value;
      } else {
        return '/'
      }
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/layout";
@import "/src/scss/tools";

@import "/src/scss/components/header";
</style>