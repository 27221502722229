<template>
  <div class="politician-swiper" :id="swiperRef">
    <div v-if="countPoliticians() >= 1">
      <swiper :slidesPerView="'auto'"
              :ref="swiperRef"
              :modules="modules"
              :navigation="true"
              :slidesPerGroup="1">
        <swiper-slide
            v-for="(politician, index) in politicianList"
            :key="index">
          <Politician
              :key="politician.id"
              :variant="variant"
              :profileImage="politician.profile_image"
              :politicianID="politician.politician_id"
              :firstName="politician.first_name"
              :lastName="politician.last_name"
              :partyColor="politician.party_color"
              :party="politician.party"
              :parliament_route_value="parliament_route_value"
              :percantage="variant === 'result-overview' ? politician.matching_value : ''"
              :linkToDetail="true"
              :linkToModal="true"
          />
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Politician from "@/components/Politician";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/virtual';

export default {
  name: 'politicianSwiper',
  props: ['swiperRef', 'variant', 'politicianList', 'parliament_route_value', 'linkToDetail', 'linkToModal'],
  components: {
    Swiper,
    Politician,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation],
    };
  },
  methods: {
    countPoliticians() {
      return this.politicianList.length;
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/swiper";
@import "/src/scss/components/politician_swiper";

</style>