<template>
  <form id="area_code_form" class="area__form form" v-on:submit.prevent="area_code_search_submission">
    <AreaCodeInput @areacode_change="areaCodeChange" />
    <div class="form__actions">
      <input id="area_code" :value="postalCode" class="visually-hidden" />
      <div id="area_code_actions">
        <button id="random_area_code_button" type="submit" class="button button--white button--loading button--large">Zufälliger {{ parliamentConstituencyTerm }}</button>
        <button id="area_code_button" type="submit" class="button button--loading button--large">Ergebnis anzeigen</button>
      </div>

    </div>
  </form>
</template>

<script>
import storageAvailable from 'storage-available';
import {inIframe} from '@/app/helper';
import AreaCodeInput from '@/components/AreaCodeInput';
import {useKCStore} from "@/app/stores/kc";
import {Parliaments} from "@/app/parliaments";

export default {
  name: 'AreaCodeForm',
  components: {
    AreaCodeInput
  },
  props: ['parliament_period_id', 'parliament_route_value'],
  data: () => ({
    postalCode: ''
  }),
  created() {
    let component = this;
    // check if the area code is in the get params
    let area_code = this.$route.query.area_code;
    if (area_code) {
      component.search_for_area_code(area_code);
    }
  },
  computed: {
    parliamentConstituencyTerm() {
      const parliaments = new Parliaments();
      const parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value);
      return parliament.constituency_term;
    }
  },
  methods: {
    areaCodeChange(area_code) {
      let areaCodeField = document.getElementById('area_code');
      if (area_code !== null) {
        areaCodeField.value = area_code;
      } else {
        areaCodeField.value = '';
      }
    },
    show_error_message(message) {
      let errorBoxes = document.getElementsByClassName("form_message form_message__error");
      if (errorBoxes.length === 0) {
        let areaCodeInput = document.querySelector('.area-code-input-group');
        let messageWrapper = document.createElement("DIV");
        let messageText = document.createTextNode(message);
        messageWrapper.setAttribute('class', 'form_message form_message__error');
        messageWrapper.appendChild(messageText);
        areaCodeInput.parentNode.insertBefore(messageWrapper, areaCodeInput.nextSibling);
      }
      else {
        errorBoxes[0].innerHTML = message;
      }
    },
    area_code_search_submission(submitEvent) {
      let area_code = submitEvent.target.elements.area_code.value;
      let submitter = submitEvent.submitter,
        submitterId = submitter.getAttribute('id');
      if (submitterId === 'random_area_code_button') {
        area_code = '00000';
        this.$matomo?.trackEvent('Area code submission', 'Choose random area code', 'Area code form');
      }
      submitter.classList.add("is-loading");

      if (area_code.length !== 5) {
        this.show_error_message('Bitte geben Sie eine Postleitzahl ein.');
        submitter.classList.remove("is-loading");
      }
      else {
        this.search_for_area_code(area_code, submitter);
        submitter.classList.remove("is-loading");
      }
    },
    search_for_area_code(area_code) {
      let payload = {
        parliament_period_id: this.parliament_period_id,
        area_code
      }
      if (storageAvailable('localStorage')) {
        const store = useKCStore();
        store.loadConstituencyDescriptorsByAreaCode(payload).
        then(() => {
          let areaCodeFormButton = document.getElementById('area_code_button');
          let checkConstituency = store.checkConstituencyDescriptors();
          switch (checkConstituency) {
            case 'constituency':
              this.$router.push({ name: "YourResult",  params: { parliament_route_value: this.parliament_route_value }})
              break;
            case 'descriptors':
              this.$router.push({ name: "ChooseDescriptors",  params: { parliament_route_value: this.parliament_route_value }})
              break;
            default:
              this.show_error_message(`Es konnte kein ${ this.parliamentConstituencyTerm } für diese Postleitzahl gefunden werden.`);
              setTimeout(function(){
                areaCodeFormButton.classList.remove("is-loading");
              }, 300);
              break;
          }
        })
      }
      else {
        // when localstorage is not available and we are in a iframe
        // redirect the user into a new tab
        if (inIframe()) {
          let routeData = this.$router.resolve({name: 'LandingpageParliament', params: { parliament_route_value: this.parliament_route_value }, query: {area_code: area_code}});
          window.open(routeData.href, '_blank');
        }
        else {
          // @todo show prettier message
          alert('Mit Ihren Sicherheitseinstellungen in Ihrem Browser können wir den Kandidierenden-Check leider nicht durchführen. Die App benötigt die Möglichkeit, Ihre Eingaben im LocalStorage des Browser zu speichern.')
        }
      }
    }
  }
}

</script>
