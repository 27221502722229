<template>
  <div
    class="area"
    v-if="candidacy_check"
  >
    <assumption-rating :parliament_route_value="parliament_route_value" :assumption_nr="1"></assumption-rating>
  </div>
  <div v-else-if="active_candidacy_checks_counter >= 0 && !candidacy_check" class="l-container l-container--small">
    <p class="text-center">Für dieses Projekt läuft zur Zeit kein Kandidierenden-Check.</p>
  </div>
</template>


<script>
import { Parliaments } from '@/app/parliaments';
import AssumptionRating from '@/components/pages/AssumptionRating';
import {useKCStore} from "@/app/stores/kc";
let parliaments = new Parliaments();

export default {
  name: 'LandingpageParliament',
  components: {
    AssumptionRating
  },
  props: ['parliament_route_value'],
  created() {
    const store = useKCStore();
    let parliament_id = parliaments.getParliamentIdByRouteValue(this.parliament_route_value);
    store.loadCandidacyCheckDataForParliament(parliament_id);
    this.parliament_id = parliament_id;
  },
  computed: {
    active_candidacy_checks_counter() {
      const store = useKCStore();
      return store.getActiveCandidacyChecksCount();
    },
    candidacy_check() {
      const store = useKCStore();
      return store.getPlayedCandidacyCheck()
    }
  }
}
</script>
