import { defineStore } from 'pinia'
import storageAvailable from 'storage-available';
import axios from 'axios';
import {apiCall} from '@/app/api';
import kc_settings from '@/app/kc_settings';
import {Parliaments} from '@/app/parliaments';

const state = () => ({
  active_candidacy_checks: null,
  played_candidacy_check: null,
});

const actions = {
  loadActiveCandidacyChecks() {
    const path = process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/active-cc';
    apiCall(path, this, 'active_candidacy_checks');
  },
  loadCandidacyCheckDataForParliament(parliament_id) {
    const path = process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/get-candidacy-check/'  + parliament_id;
    apiCall(path, this, 'played_candidacy_check');
  },
  loadConstituencyDescriptorsByAreaCode({parliament_period_id, area_code}) {
    return axios.get(process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/area-code/' + parliament_period_id + '/' + area_code)
         .then((response) => {
           if (response.data.data.id) {
             this.played_candidacy_check.attendances = response.data.data.attendances;
             delete(response.data.data.attendances);
             this.played_candidacy_check.constituency = response.data.data;
             this.played_candidacy_check.descriptors = null;
           }
           else if (response.data.data.descriptors) {
             this.played_candidacy_check.attendances = null;
             this.played_candidacy_check.constituency = null;
             this.played_candidacy_check.descriptors = response.data.data.descriptors;
           }
           else {
             console.log('It was not possible to load a constituency or descriptors');
             this.played_candidacy_check.attendances = null;
             this.played_candidacy_check.constituency = null;
             this.played_candidacy_check.descriptors = null;
           }
         })
         .catch(function(error){
           if (typeof error.response.data.meta !== 'undefined') {
             console.log(error.response.data.meta.status_message);
           }
           if (state.played_candidacy_check !== undefined &&
                state.played_candidacy_check !== null) {
             state.played_candidacy_check.attendances = null;
             state.played_candidacy_check.constituency = null;
             state.played_candidacy_check.descriptors = null;
           }
          }
         )
  },
  loadConstituency(constituency_id) {
    return axios.get(process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/constituency/' + constituency_id)
                .then((response) => {
                  if (response.data.data.id) {
                    this.played_candidacy_check.attendances = response.data.data.attendances;
                    delete(response.data.data.attendances);
                    this.played_candidacy_check.constituency = response.data.data;
                    this.played_candidacy_check.descriptors = null;
                  }
                  else {
                    console.log('It was not possible to load a constituency');
                    this.played_candidacy_check.constituency = null;
                    this.played_candidacy_check.attendances = null;
                  }
                })
                .catch(function(error){
                  if (typeof error.response.data.meta !== 'undefined') {
                    console.log(error.response.data.meta.status_message);
                  }
                  this.played_candidacy_check.constituency = null;
                  this.played_candidacy_check.attendances = null;
                  }
                )
  },
  loadResult({parliament_period_id, constituency_id}) {
    const store = this;
    const userData = {
      "user_positions": this.getAllUserPositions()
    };
    return axios.post(process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/result/' + parliament_period_id +'/' + constituency_id, userData)
                .then((response) => {
                  if (! response.data.data) {
                    console.log('It was not possible to load the result.');
                    return null;
                  } else {
                    return response.data.data;
                  }
                })
                .catch(function(error){
                  if (typeof error.response.data.meta !== 'undefined') {
                    console.log(error.response.data.meta.status_message);
                  }
                  return null;
                }).then((data) => {
                  store.played_candidacy_check.result = data;
                  // set the matching value for each attendance
                  if (store.played_candidacy_check.result && store.played_candidacy_check.result.matching) {
                    store.played_candidacy_check.result.matching.forEach(function(matchingElement) {
                      let attendances = store.played_candidacy_check.attendances,
                          attendance = attendances.find(element => element.id === matchingElement.attendance_id);
                      attendance.matching_value = matchingElement.matching_value;
                    });
                  }
                })
  }
}

const getters = {
  getActiveCandidacyChecks: state => () => {
    return state.active_candidacy_checks;
  },
  getActiveCandidacyChecksCount: state => () => {
    if (Array.isArray(state.active_candidacy_checks)){
      return state.active_candidacy_checks.length;
    }
    return null;
  },
  getPlayedCandidacyCheck: state => () => {
    return state.played_candidacy_check;
  },
  getPlayedCandidacyCheckId: state => () => {
    let candidacyCheck = state.getPlayedCandidacyCheck();
    if (candidacyCheck) {
      return candidacyCheck.candidacy_check_id;
    }
    return null;
  },
  getConstituency: state => () => {
    return state.played_candidacy_check.constituency;
  },
  getDescriptors: state => () => {
    if (state.played_candidacy_check.descriptors === null) {
      return null;
    }
    return state.played_candidacy_check.descriptors;
  },
  // returns a string indicating if constituency is "not_set",
  // "descriptors" are set or a single "constituency" is set.
  // Fallback is "error"
  checkConstituencyDescriptors: state => () => {
    let constituency = state.getConstituency(),
        descriptors = state.getDescriptors();
    if ((constituency === undefined && descriptors === undefined) ||
      (constituency === null && descriptors === null)) {
      return 'not_set';
    }
    else if (descriptors !== null) {
      return 'descriptors';
    }
    else if (constituency !== null) {
      return 'constituency';
    }

    return 'error';
  },
  getConstituencyName: state => () => {
    let constituency = state.getConstituency();
    if (constituency === null || constituency.name === null) {
      return '';
    }
    return constituency.name;
  },
  getConstituencyNumber: state => () => {
    let constituency = state.getConstituency();
    if (constituency === undefined ||constituency === null || constituency.number === null) {
      return '';
    }
    return constituency.number;
  },
  getConstituencyId: state => () => {
    let constituency = state.getConstituency();
    if (constituency === undefined || constituency === null || constituency.number === null) {
      return '';
    }
    return constituency.id;
  },
  getCCAttendances: state => (filter_by_party = kc_settings.FILTER_BY_PARTY_DEFAULT, sorting_by = kc_settings.SORTING_ALPHABETICAL_ASC) => {
    let attendances = state.played_candidacy_check.attendances;
    if (filter_by_party !== kc_settings.FILTER_BY_PARTY_DEFAULT) {
      attendances = attendances.filter(function(attendance) {
        return attendance.party_id === filter_by_party;
      });
    }
    switch(sorting_by) {
      case kc_settings.SORTING_ALPHABETICAL_DESC:
        attendances = attendances.slice().sort(function(a, b) {
          if ( a.last_name > b.last_name ){
            return -1;
          }
          if ( a.last_name < b.last_name ){
            return 1;
          }
          return 0;
        })
        break;
      case kc_settings.SORTING_MATCHING_DESC:
        attendances = attendances.slice().sort(function(a, b) {
          if ( a.matching_value > b.matching_value ){
            return -1;
          }
          else if ( a.matching_value < b.matching_value ){
            return 1;
          }
          else {
            if ( a.last_name > b.last_name ){
              return 1;
            }
            else if ( a.last_name < b.last_name ){
              return -1;
            }
          }
          return 0;
        })
        break;
      case kc_settings.SORTING_MATCHING_ASC:
        attendances = attendances.slice().sort(function(a, b) {
          if ( a.matching_value < b.matching_value ){
            return -1;
          }
          if ( a.matching_value > b.matching_value ){
            return 1;
          }
          return 0;
        })
        break;
      default:
        // default is alphabetical asc
        attendances = attendances.slice().sort(function(a, b) {
          if ( a.last_name < b.last_name ){
            return -1;
          }
          if ( a.last_name > b.last_name ){
            return 1;
          }
          return 0;
        })
        break;
    }
    return attendances;
  },
  getCCAttendancyById: state => (attendance_id) => {
    let attendances = state.getCCAttendances();
    if (attendances === null) {
      return null;
    }
    return attendances.find(element => element.id === attendance_id);
  },
  getCCAttendancyByPoliticianId: state => (politician_id) => {
    let attendances = state.getCCAttendances();
    if (attendances === null) {
      return null;
    }
    return attendances.find(element => element.politician_id === politician_id);
  },

  getAllAssumptions: state => () => {
    let played_candidacy_check = state.getPlayedCandidacyCheck();
    if (played_candidacy_check !== undefined &&
      played_candidacy_check !== null &&
      played_candidacy_check.assumptions !== null) {
      return played_candidacy_check.assumptions;
    }
    return null;
  },
  getAssumption: state => (assumption_nr) => {
    let assumptions = state.getAllAssumptions();
    if (assumptions !== null) {
      let data = assumptions.find(element => element.number === assumption_nr);
      if (data) {
        return data;
      }
    }

    return null;
  },
  getAssumptionsCount: state => () => {
    let assumptions = state.getAllAssumptions();
    if (assumptions) {
      return assumptions.length;
    }

    return 0;
  },
  getAllUserPositions: state => () => {
    let played_candidacy_check = state.getPlayedCandidacyCheck();
    if (played_candidacy_check !== undefined &&
      played_candidacy_check !== null &&
      played_candidacy_check.user_positions !== null) {
      return played_candidacy_check.user_positions;
    }
    return null;
  },
  getUserPositionByAssumptionNr: state => (assumption_nr) => {
    let allUserPositions = state.getAllUserPositions();
    if (allUserPositions) {
      let userposition = allUserPositions.find(element => element.assumption_nr === assumption_nr);
      if (userposition) {
        return userposition;
      }
    }
    return null;
  },
  getUserPositionsCount: state => () => {
    let positions = state.getAllUserPositions();
    if (positions !== null) {
      return positions.length;
    }
    return 0;
  },
  getElectionYear: state => () => {
    let candidacyCheck = state.getPlayedCandidacyCheck();
    if (candidacyCheck) {
      return candidacyCheck.election_year;
    }
    return null;
  },
  getParliamentPeriodId: state => () => {
    let candidacyCheck = state.getPlayedCandidacyCheck();
    if (candidacyCheck) {
      return candidacyCheck.parliament_period_id;
    }
    return null;
  },
  getParliamentId: state => () => {
    let candidacyCheck = state.getPlayedCandidacyCheck();
    if (candidacyCheck) {
      return candidacyCheck.parliament_id;
    }
    return null;
  },
  getAllCandidatesPositions: state => () => {
    let played_candidacy_check = state.getPlayedCandidacyCheck();
    if (played_candidacy_check === null) {
      return null
    }
    if (typeof played_candidacy_check.result === 'undefined') {
      return null;
    }
    if (typeof played_candidacy_check.result.positions === 'undefined') {
      return null;
    }

    return played_candidacy_check.result.positions;
  },
  getPositionsByPoliticianId: state => (politician_id) => {
    let allCandidatesPositions = state.getAllCandidatesPositions();
    let positionsPolitician = allCandidatesPositions.filter(element => element.politician_id === politician_id);
    // sort by assumption number asc
    positionsPolitician.sort(function (a, b) {
      var keyA = a.assumption_nr,
        keyB = b.assumption_nr;
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });

    return positionsPolitician;
  },
  getPositionsByAssumptionId: state => (assumption_nr) => {
    let allCandidatesPositions = state.getAllCandidatesPositions();
    return allCandidatesPositions.filter(element => element.assumption_nr === assumption_nr);
  },
  getFilteredCandidatesPositions: state => (assumption_nr, rating) => {
    let allCandidatesPositions = state.getAllCandidatesPositions();
    return allCandidatesPositions.filter(element => (element.assumption_nr === assumption_nr && element.rating === rating));
  },
  getResult: state => () => {
    let played_candidacy_check = state.getPlayedCandidacyCheck();
    if (played_candidacy_check !== null) {
      if (typeof played_candidacy_check.result !== 'undefined') {
        return played_candidacy_check.result;
      }
    }
    return null;
  },
  getParliamentLandingpageLink: state => () => {
    let played_candidacy_check = state.getPlayedCandidacyCheck();
    if (played_candidacy_check !== null) {
      if (typeof played_candidacy_check.aw_link !== 'undefined') {
        return played_candidacy_check.aw_link;
      }
    }
    return null;
  },
  getAwLandingpage: () => () => {
    return 'https://www.abgeordnetenwatch.de';
  },
  getTrackingParameters: state => () => {
    let parliaments = new Parliaments(),
        trackingParameters = new Object();
    trackingParameters.pk_campaign = 'kc';

    let parliamentId = state.getParliamentId(),
        election_year = state.getElectionYear();
    if (parliamentId !== null && election_year !== null) {
      let parliament = parliaments.getParliamentById(parliamentId);
      trackingParameters.pk_kwd = parliament.route_parameter_value + '_' + election_year;
    }

    return trackingParameters;
  },
  addTrackingParametersToLink: state => (link) => {
    let trackingParameters = state.getTrackingParameters();
    link = link + '?pk_campaign='+ trackingParameters.pk_campaign;

    if ('pk_kwd' in trackingParameters) {
      link = link + '&pk_kwd=' + trackingParameters.pk_kwd;
    }

    return link;
  }
}

let storeOptions = {
  state,
  getters,
  actions,
}
// TODO: Drop check for local storage, because it should be supported by all relevant browsers.
if (storageAvailable('localStorage')) {
    storeOptions.persist = true;
}

export const useKCStore = defineStore('kc', storeOptions);
