import { createApp } from 'vue'
import App from './App.vue'
import router from './app/routes.js'
import pinia from "./app/store.js";
import { useKCStore } from "./app/stores/kc";
import VueMatomo from 'vue-matomo'

const app = createApp(App);
app.use(pinia);
const store = useKCStore();
app.use(store);
app.use(router);
app.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://a7s.parliamentwatch.org',
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,
  router: router,
  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: true,
  enableHeartBeatTimer: true,
  heartBeatTimerInterval: 15,
  // Whether or not to log debug information
  // Default: false
  debug: false,
  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: []
});
app.mount('#app');
