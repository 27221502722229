<!-- Code taken from https://www.catalinmunteanu.com/vue-pin-code-input-component/ -->
<template>
  <div>
    <div class="area-code-input-group">
      <input v-model.number="pin_0"
             v-on:keyup.right="pin_focus('pin_1')"
             v-on:keypress="is_valid_pin_value($event, 'pin_0')"
             ref="pin_0" type="tel" class="area-code-input-single-number" autocomplete="disabled" lpignore="true">
      <input v-model.number="pin_1"
             v-on:keyup.left="pin_focus('pin_0')"
             v-on:keyup.right="pin_focus('pin_2')"
             v-on:keypress="is_valid_pin_value($event, 'pin_1')"
             ref="pin_1" type="tel" class="area-code-input-single-number" autocomplete="disabled" lpignore="true">
      <input v-model.number="pin_2"
             v-on:keyup.left="pin_focus('pin_1')"
             v-on:keyup.right="pin_focus('pin_3')"
             v-on:keypress="is_valid_pin_value($event, 'pin_2')"
             ref="pin_2" type="tel" class="area-code-input-single-number" autocomplete="disabled" lpignore="true">
      <input v-model.number="pin_3"
             v-on:keyup.left="pin_focus('pin_2')"
             v-on:keyup.right="pin_focus('pin_4')"
             v-on:keypress="is_valid_pin_value($event, 'pin_3')"
             ref="pin_3" type="tel" class="area-code-input-single-number" autocomplete="disabled" lpignore="true">
      <input v-model.number="pin_4"
             v-on:keyup.left="pin_focus('pin_3')"
             v-on:keypress="is_valid_pin_value($event, 'pin_4')"
             ref="pin_4" type="tel" class="area-code-input-single-number" autocomplete="disabled" lpignore="true">
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      pin_0: null,
      pin_1: null,
      pin_2: null,
      pin_3: null,
      pin_4: null
    }
  },
  computed: {
    area_code: function () {
      if (this.pin_0 !== null &&
          this.pin_1 !== null &&
          this.pin_2 !== null &&
          this.pin_3 !== null &&
          this.pin_4 !== null) {
        return '' + this.pin_0 + this.pin_1 + this.pin_2 + this.pin_3 + this.pin_4;
      }
      return null;
    }
  },
  watch: {
    area_code: function () {
      this.$emit('areacode_change', this.area_code)
    },
    pin_0: function (nv) {
      if (nv.toString().length !== 0) {
        this.$refs.pin_1.focus()
        this.$refs.pin_1.select()
      }
    },
    pin_1: function (nv) {
      if (nv.toString().length !== 0) {
        this.$refs.pin_2.focus()
        this.$refs.pin_2.select()
      }
    },
    pin_2: function (nv) {
      if (nv.toString().length !== 0) {
        this.$refs.pin_3.focus()
        this.$refs.pin_3.select()
      }
    },
    pin_3: function (nv) {
      if (nv.toString().length !== 0) {
        this.$refs.pin_4.focus()
        this.$refs.pin_4.select()
      }
    }
  },
  methods: {
    pin_focus: function (ref) {
      this.$refs[ref].focus()
      this.$refs[ref].select()
    },
    is_valid_pin_value: function (e, pin_N) {
      const char = String.fromCharCode(e.keyCode);
      const is_value_selected = this[pin_N] !== null && this.$refs[pin_N].selectionStart === 0 && this.$refs[pin_N].selectionEnd === this[pin_N].toString().length;

      if ((this[pin_N] === null || this[pin_N].toString().length === 0 || is_value_selected) && parseInt(char, 10) >= 0 && parseInt(char, 10) <= 9) {
        return true
      }

      // allow pressing enter when the last number is filled in
      if (!(pin_N === 'pin_4' && e.key === 'Enter')) {
        e.preventDefault()
      }
    }
  }
}
</script>