<template>
  <div class="politician-detail__result__item" :data-assumption-rating-id="id">
    <div class="politician-detail__result__item__inner" @click="openAssumptionModal">
      <div class="politician-detail__result__item__topic" v-if="topic" :title="topic"><div class="politician-detail__result__item__topic__inner">{{ topic }}</div></div>
      <div class="politician-detail__result__item__behavior-politician">
        <i class="aw-icon-check" v-if="attendanceRating === 'agree'"></i>
        <i class="aw-icon-circle-o" v-if="attendanceRating === 'neutral'"></i>
        <i class="aw-icon-close" v-if="attendanceRating === 'disagree'"></i>
        <div class="politician-detail__result__item__statement" v-if="attendanceReason">
          Begründung
        </div>
      </div>
      <div class="politician-detail__result__item__behavior-user">
        <i class="aw-icon-check" v-if="userRating === 'agree'"></i>
        <i class="aw-icon-circle-o" v-if="userRating === 'neutral'"></i>
        <i class="aw-icon-close" v-if="userRating === 'disagree'"></i>
      </div>
    </div>

    <AssumptionDetail
        v-if="showAssumptionModal"
        :number="number"
        :id="id"
        :topic="topic"
        :attendanceRating="attendanceRating"
        :attendanceReason="attendanceReason"
        :attendanceID="attendanceID"
        visibilityState="true"
    />
  </div>
</template>


<script>
import AssumptionDetail from "@/components/result-components/AssumptionDetail";

export default {
  name: 'PoliticianDetailResultItem',
  props: ['number', 'id', 'topic', 'userRating', 'attendanceRating', 'attendanceReason', 'attendanceID'],
  components: {
    AssumptionDetail
  },
  data() {
    return {
      showAssumptionModal: false
    }
  },
  methods: {
    openAssumptionModal () {
      let body = document.querySelector('html');
      body.classList.add('is-open-modal');

      this.showAssumptionModal = true;
      this.$matomo?.trackEvent('Politician detail', `Open assumption`, 'Result item');
    }
  }
}
</script>
