<template>
  <component :is="linkToDetail?'router-link':'span'" :class="classObject" :to="candidate_detail_link" @click="openParentAssumptionModal">
    <div class="politician-tile__image">
      <img :src="profileImage" alt="">
    </div>

    <div class="politician-tile__text">
      <div class="politician-tile__percentage" v-if="percantage">{{ percantage }}%</div>

      <div class="politician-tile__name">
        {{ firstName }}
        {{ lastName }}
      </div>

      <div class="party" v-if="party">
        <div class="party__indicator" :style="{ background: partyColor }" v-if="partyColor"></div>
        <div class="party__name">{{ party }}</div>
      </div>
    </div>
  </component>
</template>

<script>

import {useKCStore} from "@/app/stores/kc";

export default {
  name: 'Politician',
  props: ['linkToDetail', 'linkToModal', 'activeAssumption', 'clickTarget', 'politicianID', 'profileImage', 'firstName', 'lastName', 'partyColor', 'party', 'percantage', 'parliament_route_value', 'variant', 'podiumstep'],
  computed: {
    classObject: function () {
      return {
        'politician-tile': true,
        'politician-tile--modal': this.linkToModal,
        'politician-tile--compare': this.variant === 'compare',
        'politician-tile--with-reason': this.attendanceHasReason(),
        'politician-tile--podium': this.variant === 'podium',
        'politician-tile--result-overview': this.variant === 'result-overview',
        'politician-tile--podium__step1': this.podiumstep === 1,
        'politician-tile--podium__step2': this.podiumstep === 2,
        'politician-tile--podium__step3': this.podiumstep === 3
      }
    },
    candidate_detail_link() {
      if (this.linkToDetail) {
        let data = '';
        if (this.politicianID) {
          data = '/' + this.parliament_route_value + '/ergebnis-details/kandidat/' + this.politicianID;
        }
        return data;
      } else {
        return false;
      }
    }
  },
  methods: {
    openParentAssumptionModal() {
      this.$matomo?.trackEvent('Result', 'Open politician detail', `Politician ${this.variant}`);
      if (this.linkToModal) {
        this.$parent.$parent.attendance_id = this.politicianID;
        this.$parent.$parent.openAssumptionModal();
      }
    },
    attendanceHasReason() {
      const store = useKCStore();
      if (!this.activeAssumption) {
        return false;
      }
      if (
          this.linkToModal &&
          store.getPositionsByPoliticianId(this.politicianID).filter(
              element => element.assumption_nr === this.activeAssumption
          )[0].reason
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/politician";

</style>
