<template>
    <div class="app__inner">
      <HeaderDefault />
      <router-view></router-view>
      <FooterDefault />
    </div>
</template>

<script>
import FooterDefault from '@/components/footer/FooterDefault';
import HeaderDefault from "@/components/header/HeaderDefault";

export default {
  name: 'App',
  components: {
    HeaderDefault,
    FooterDefault
  },
  computed: {
    check_touch_device() {
      return (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));
    },
  },
  created:function() {
    this.set_app_min_height();
    this.set_touch_class();
  },
  methods: {
    set_app_min_height() {
      document.addEventListener("DOMContentLoaded", function() {
        let appContainer = document.getElementById('app');
        appContainer.style.minHeight = document.documentElement.clientHeight + 'px';
      });
    },
    set_touch_class() {
      if (this.check_touch_device) {
        let htmlTag = document.querySelector('html')
        htmlTag.classList.add("is-touched");
      }
    }
  }
}

</script>

<style lang="scss">

@import "/src/scss/base";

</style>