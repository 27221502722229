

class Parliaments {
  /**
   * IMPORTANT: changes here may also needed to be done in public/php/Parliaments.php
   */
  getMapping() {
    return [
      {
        route_parameter_value: 'baden-wuerttemberg',
        parliament_id: 6,
        label: 'Landtagswahl Baden-Württemberg',
        icon: 'de-bw',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'bayern',
        parliament_id: 13,
        label: 'Landtagswahl Bayern',
        icon: 'de-by',
        constituency_term: 'Stimmkreis'
      },
      {
        route_parameter_value: 'berlin',
        parliament_id: 2,
        label: 'Abgeordnetenhauswahl Berlin',
        icon: 'de-be',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'brandenburg',
        parliament_id: 16,
        label: 'Landtagswahl Brandenburg',
        icon: 'de-bb',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'bremen',
        parliament_id: 10,
        label: 'Bürgerschaftswahl Bremen',
        icon: 'de-hb',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'bundestag',
        parliament_id: 5,
        label: 'Bundestagswahl',
        icon: 'de-bu',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'eu-parlament',
        parliament_id: 1,
        label: 'Europwahl EU-Parlament',
        icon: 'de-eu',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'hamburg',
        parliament_id: 3,
        label: 'Bürgerschaftswahl Hamburg',
        icon: 'de-hh',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'hessen',
        parliament_id: 11,
        label: 'Landtagswahl Hessen',
        icon: 'de-he',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'mecklenburg-vorpommern',
        parliament_id: 9,
        label: 'Landtagswahl Mecklenburg-Vorpommern',
        icon: 'de-mv',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'niedersachsen',
        parliament_id: 12,
        label: 'Landtagswahl Niedersachen',
        icon: 'de-ni',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'nordrhein-westfalen',
        parliament_id: 4,
        label: 'Landtagswahl Nordrhein-Westfalen',
        icon: 'de-nw',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'rheinland-pfalz',
        parliament_id: 7,
        label: 'Landtagswahl Rheinland Pfalz',
        icon: 'de-rp',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'saarland',
        parliament_id: 14,
        label: 'Landtagswahl Saarland',
        icon: 'de-sl',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'sachsen',
        parliament_id: 17,
        label: 'Landtagswahl Sachsen',
        icon: 'de-sn',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'sachsen-anhalt',
        parliament_id: 8,
        label: 'Landtagswahl Sachsen-Anhalt',
        icon: 'de-st',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'schleswig-holstein',
        parliament_id: 18,
        label: 'Landtagswahl Schleswig-Holstein',
        icon: 'de-sh',
        constituency_term: 'Wahlkreis'
      },
      {
        route_parameter_value: 'thueringen',
        parliament_id: 15,
        label: 'Landtagswahl Thüringen',
        icon: 'de-th',
        constituency_term: 'Wahlkreis'
      }
    ];
  }

  getParliamentIdByRouteValue(route_value) {
    const data = this.getMapping().find(element => element.route_parameter_value === route_value);
    if (data) {
      return data.parliament_id;
    }
    return null;
  }

  getParliamentByRouteValue(route_value) {
    const data = this.getMapping().find(element => element.route_parameter_value === route_value);
    if (data) {
      return data;
    }
    return null;
  }

  getParliamentById(parliament_id) {
    const data = this.getMapping().find(element => element.parliament_id === parliament_id);
    if (data) {
      return data;
    }
    return null;
  }

  getParliamentRouteParameterValueById(parliament_id) {
    const data = this.getMapping().find(element => element.parliament_id === parliament_id);
    if (data) {
      return data.route_parameter_value;
    }
    return null;
  }
}

export { Parliaments };
