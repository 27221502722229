<template>
  <div class="assumption-compare-swiper">
    <div class="form__select assumption-compare-swiper__select">
      <v-select
          :options="assumptionOptions"
          id="assumptionSelect"
          @option:selected="assumptionSelect($event)"
          :searchable="false"
          :clearable="false"
          v-model="selected_assumption"
          label="topic"
      ></v-select>
    </div>

    <swiper
        ref="assumptionCompareSwiper"
        :autoHeight="true"
        :loop="true"
        @slideChange="swiperChanged"
        :slidesPerView="1"
    >
      <swiper-slide
          class="assumption-compare-swiper__item"
          v-for="assumption in assumptions()"
          :key="assumption.number"
      >
        <div class="assumption-compare-swiper__quote">{{ assumptionText(assumption) }}</div>
        <div class="assumption-compare-swiper__behavior">
          <i :class="userRatingIconClass()"></i>
          {{ userRatingText() }}
        </div>
      </swiper-slide>
    </swiper>
    <div class="navigation">
      <button @click="swiperPrev" class="swiper-button-prev"></button>
      <button @click="swiperNext" class="swiper-button-next"></button>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import kc_settings from '@/app/kc_settings';
import vSelect from 'vue-select'
import 'swiper/css';
import 'swiper/css/navigation';
import {useKCStore} from "@/app/stores/kc";

export default {
  name: 'AssumptionSelect',
  components: {
    vSelect,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      selected_assumption: this.default_assumption
    }
  },
  computed: {
    swiper() {
      return document.querySelector('.assumption-compare-swiper .swiper').swiper;
    },
    assumptionOptions() {
      return this.assumptions();
    }
  },
  props: ['default_assumption'],
  methods: {
    swiperPrev() {
      this.swiper.slidePrev();
      this.$matomo?.trackEvent('Result', `Swipe assumption`, 'Assumption compare');

    },
    swiperNext() {
      this.swiper.slideNext();
      this.$matomo?.trackEvent('Result', `Swipe assumption`, 'Assumption compare');
    },
    assumptions() {
      const store = useKCStore();
      return store.getAllAssumptions();
    },
    assumptionText(assumption) {
      return assumption.text;
    },
    assumptionSelect($selectedOption) {
      const swiperInstance = this.swiper;
      swiperInstance.slideTo($selectedOption.number, 1000, false);
      this.$emit('assumptionSet', parseInt($selectedOption.number));
      this.selected_assumption = $selectedOption.topic;
      this.$matomo?.trackEvent('Result', `Select assumption`, 'Assumption compare');
    },
    swiperChanged(swiper) {
      this.$emit('assumptionSet', swiper.realIndex + 1);
      this.selected_assumption = this.assumptions()[swiper.realIndex];
    },

    userRatingText() {
      const store = useKCStore();
      let userPosition = store.getUserPositionByAssumptionNr(this.selected_assumption.number);
      if (userPosition && typeof userPosition.rating !== 'undefined') {
        switch (userPosition.rating) {
          case kc_settings.RATING_AGREE:
            return 'Ich stimme zu';
          case kc_settings.RATING_DISAGREE:
            return 'Ich lehne ab';
          case kc_settings.RATING_NEUTRAL:
            return 'Ich bin neutral';
          default:
            return '';
        }
      }
    },
    userRatingIconClass() {
      const store = useKCStore();
      let userPosition = store.getUserPositionByAssumptionNr(this.selected_assumption.number);
      if (userPosition && typeof userPosition.rating !== 'undefined') {
        switch (userPosition.rating) {
          case kc_settings.RATING_AGREE:
            return 'aw-icon-check aw-icon--large';
          case kc_settings.RATING_DISAGREE:
            return 'aw-icon-close aw-icon--large';
          case kc_settings.RATING_NEUTRAL:
            return 'aw-icon-circle-o aw-icon--large';
          default:
            return '';
        }
      }
    }
  }
}

</script>
