<template>
  <div :class="classObject">
    <div class="parliament-context__item" v-if="variant != 'result'">{{ parliamentLabel }} {{ parliamentYear }}</div>
    <div class="parliament-context__item parliament-context__label" v-if="variant == 'result'">
      {{ parliamentConstituencyTerm }} <strong>{{ constituencyNumber }}</strong> {{ constituencyName }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ParliamentContext',
  props: ['parliamentLabel', 'parliamentYear', 'parliamentConstituencyTerm', 'constituencyName', 'constituencyNumber', 'variant'],
  computed: {
    classObject: function () {
      return {
        'parliament-context': true,
        'parliament-context--result': this.variant === 'result'
      }
    }
  }
}
</script>
