<template>
  <div>
    <div class="tabs-nav">
      <ul class="tabs-nav__list">
        <li class="tabs-nav__item">
          <a class="tabs-nav__link" @click.prevent="setFilterRating('agree')" :class="{ active: isActive('agree') }"><i class="aw-icon-check"></i> <span class="tabs-nav__link__label">Dafür</span> ({{ getPositionsCount('agree') }})</a>
        </li>
        <li class="tabs-nav__item">
          <a class="tabs-nav__link" @click.prevent="setFilterRating('disagree')" :class="{ active: isActive('disagree') }"><i class="aw-icon-close"></i> <span class="tabs-nav__link__label">Dagegen</span> ({{ getPositionsCount('disagree') }})</a>
        </li>
        <li class="tabs-nav__item">
          <a class="tabs-nav__link" @click.prevent="setFilterRating('neutral')" :class="{ active: isActive('neutral') }"><i class="aw-icon-circle-o"></i> <span class="tabs-nav__link__label">Neutral</span> ({{ getPositionsCount('neutral') }})</a>
        </li>
      </ul>
    </div>
    <div class="tabs-content">
      <div class="tabs-content__pane">
        <div class="assumption-compare-tab">
          <AssumptionTabsTeaser :rating_selected="rating_selected" :user_rating="user_rating" :count_positions="getPositionsCount(this.rating_selected)" :count_positions_total="getPositionsTotalCount()" />
          <div class="assumption-compare-tab__swiper">
            <PoliticianSwiper
                swiperRef="politicianSwiperAssumption"
                variant="compare"
                :politicianList="getPoliticianList()"
                :linkToModal="true"
                :parliament_route_value="parliament_route_value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PoliticianSwiper from "@/components/result-components/PoliticianSwiper";
import AssumptionTabsTeaser from '@/components/result-components/AssumptionTabsTeaser';
import {useKCStore} from "@/app/stores/kc";

export default {
  name: 'AssumptionRatingTabs',
  components: {PoliticianSwiper, AssumptionTabsTeaser},
  props: ['assumption', 'rating_selected', 'user_rating', 'parliament_route_value'],
  methods: {
    isActive (menuItem) {
      return this.rating_selected === menuItem
    },
    setFilterRating (rating) {
      this.$emit('ratingSet', rating);
    },
    getPoliticianList() {
      const store = useKCStore();
      let positions = this.getPositions(),
          attendances = [];
      positions.forEach(function(position) {
        attendances.push(store.getCCAttendancyById(position.attendance_id));
      }, this)
      return attendances;
    },
    getPositions() {
      const store = useKCStore();
      return store.getFilteredCandidatesPositions(this.getAssumptionNr(), this.rating_selected);
    },
    getPositionsCount(rating) {
      const store = useKCStore();
      return store.getFilteredCandidatesPositions(this.getAssumptionNr(), rating).length;
    },
    getAssumptionNr() {
      return this.assumption.number;
    },
    getPositionsTotalCount() {
      const store = useKCStore();
      return store.getPositionsByAssumptionId(this.getAssumptionNr()).length;
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/tabs";
@import "/src/scss/components/assumption_tabs";

</style>