<template>
  <div class="newsletter-teaser" :style="{ backgroundImage: 'url(' + require('@/assets/result/aw_newsletter_bg.jpg') + ')' }">
    <form class="form newsletter-teaser__form" autocomplete="disabled" id="newsletter-form" v-on:submit.prevent="newsletter_submission">
      <img alt="Logo von abgeordnetenwatch.de" src="@/assets/aw_logo.svg" width="181" height="18">

      <h2>Newsletter</h2>
      <p class="subtitle">Exklusive Recherchen zu Lobbyismus, Parteispenden und Nebeneinkünften. Kostenlos!</p>

      <div class="newsletter-teaser__form__combine">
        <div class="newsletter-teaser__form__input">
          <input id="email" name="email" type="email" placeholder="Ihre E-Mail-Adresse*" required />
        </div>
        <button class="button button--large button--loading">Abonnieren</button>
        <div :class="['form_message', formMessageTypeClass]" v-if="showFormMessage" v-html="formMessageText"></div>
      </div>

      <p class="form__hint">* Mit der Speicherung meiner Daten für den Empfang des Newsletters gemäß der <a href="https://www.abgeordnetenwatch.de/datenschutzerklaerung" target="_blank">Datenschutzerklärung</a> bin ich einverstanden.</p>
    </form>

    <div class="newsletter-teaser__copyright">© Deutscher Bundestag / Thomas Trutschel / photothek.net</div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'pinia'
import { useKCStore } from '@/app/stores/kc';

export default {
  name: 'NewsletterTeaser',
  computed: {
    ...mapState(useKCStore,[
      'getTrackingParameters'
    ])
  },
  methods: {
    newsletter_submission(event) {
      let email = event.target.elements.email.value,
          button = event.submitter,
          component = this,
          postData = {
            "email": email
          };
      Object.assign(postData, this.getTrackingParameters());
      button.classList.add('is-loading');

      axios.post(process.env.VUE_APP_AW_CC_API_BASEPATH +'cc-api/newsletter', postData)
           .then((response) => {

             button.classList.remove('is-loading');
             if (response.data.data.message) {
               component.show_message(response.data.data.message, 'success');
               this.$matomo?.trackEvent('Result', 'Subscribe newsletter', `Newsletter form`);
             }
             else {
               console.log('The response had not the expected structure.');
               component.show_message('Es ist ein unklarer Fehler aufgetreten. Bitte prüfen Sie, ob Sie von uns eine Bestätigungsemail erhalten haben. Falls nicht, müssen Sie Ihre Email noch einmal eintragen.', 'error');
             }
           })
           .catch(function(error){
             console.log(error);
             button.classList.remove('is-loading');
             if (typeof error.response.data.meta !== 'undefined' &&
                 typeof error.response.data.meta.status_message !== 'undefined') {
               component.show_message(error.response.data.meta.status_message, 'error');
             }
             else {
               component.show_message('Es ist ein Fehler aufgetreten.', 'error');
             }
           })
    },
    show_message(message, type) {
      if (!document.querySelector(".form__error")) {
        this.formMessageTypeClass = 'form_message__' + type;
        this.formMessageText = message;
        this.showFormMessage = true;
      }
    }
  },
  data() {
    return {
      showFormMessage: false,
      formMessageTypeClass: false,
      formMessageText: false
    }
  }
}

</script>
