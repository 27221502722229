<template>
  <div class="footer">
    <div class="l-container">
      <div class="footer__cols">
        <div class="footer__cols__item">
          <ul class="footer-links">
            <li><a :href="getPrivacyStatementLink" target="_blank">Datenschutzerklärung</a></li>
            <li><a :href="getImpressumLink" target="_blank">Impressum</a></li>
          </ul>
        </div>
        <div class="footer__cols__item footer__cols__item--logo">
          <span class="footer__cols__item__label">
            Ein Service von
          </span>
          <a :href="getHomepageLink" target="_blank" class="footer__cols__item__image">
            <img alt="Logo von abgeordnetenwatch.de" src="@/assets/aw_logo.svg" width="181" height="18">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useKCStore } from '@/app/stores/kc';

export default {
  name: 'FooterDefault',
  computed: {
    ...mapState(useKCStore, [
      'getAwLandingpage',
      'getParliamentLandingpageLink',
      'addTrackingParametersToLink'
    ]),
    getAwLink() {
      let link = '',
          parliamentLandingpageLink = this.getParliamentLandingpageLink();
      if (parliamentLandingpageLink === null) {
        link = this.getAwLandingpage();
      }
      else {
        link = parliamentLandingpageLink;
      }

      return this.addTrackingParametersToLink(link);
    },
    getImpressumLink() {
      return this.addTrackingParametersToLink('https://www.abgeordnetenwatch.de/ueber-uns/impressum');
    },
    getHomepageLink() {
      return this.addTrackingParametersToLink('https://www.abgeordnetenwatch.de');
    },
    getPrivacyStatementLink() {
      return this.addTrackingParametersToLink('https://www.abgeordnetenwatch.de/datenschutzerklaerung');
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/layout";
@import "/src/scss/tools";

@import "/src/scss/components/footer";

</style>