<template>
  <div class="l-container">
    <div class="politician-detail">
      <div class="politician-detail__back">
        <a :href="this.back_link" class="politician-detail__back__button" @click="backLinkBehavior($event)">
          <i class="aw-icon-chevron-right aw-icon--large"></i> Zurück zum Ergebnis
        </a>
      </div>
      <div class="politician-detail__sidebar">
        <div class="politician-detail__sidebar__image" v-if="attendance.profile_image">
          <img :src="attendance.profile_image" :srcset="`${attendance.profile_image} 1x, ${attendance.profile_image_retina} 2x`" :alt="'Portraitfoto von ' + attendance.first_name + ' ' + attendance.last_name">
          <div class="politician-detail__sidebar__image__copyright" v-if="attendance.profile_image_copyright">
            © <span v-html="attendance.profile_image_copyright"></span>
          </div>
        </div>
        <div class="politician-detail__sidebar__content">
          <div class="politician-detail__sidebar__parliament">
            <div class="politician-detail__sidebar__parliament__icon">
              <i :class="'aw-icon-' + parliament.icon"></i>
            </div>
            <div class="politician-detail__sidebar__parliament__label">
              {{ parliament.label }} {{ parliament_election_year }}
            </div>
          </div>
          <div class="politician-detail__sidebar__score">
            <div class="politician-detail__sidebar__score__label">Sie stimmen zu</div>
            <div class="politician-detail__sidebar__score__value">{{ match_score }} %</div>
            <div class="politician-detail__sidebar__score__label">überein mit</div>
          </div>
          <div class="politician-detail__sidebar__reference">
            <div class="politician-detail__sidebar__reference__name">{{ attendance.first_name }} {{ attendance.last_name }}</div>
            <div class="politician-detail__sidebar__reference__party">
              <div class="party" v-if="attendance.party">
                <div class="party__indicator" :style="{ background: attendance.party_color }"></div>
                <div class="party__name">{{ attendance.party }}</div>
              </div>
            </div>
          </div>
        </div>

        <a :href="getProfileLink(attendance.profile_link)" class="politician-detail__sidebar__link" target="_blank" v-if="attendance.profile_link">
          Zum Profil von {{ attendance.first_name }} {{ attendance.last_name }} auf <img alt="Logo von abgeordnetenwatch.de" src="@/assets/aw_logo.svg" width="160" height="16">
        </a>
      </div>
      <div class="politician-detail__content">
        <div class="politician-detail__result" data-active-result-page="1">
          <div class="politician-detail__result__item politician-detail__result__item--header">
            <div class="politician-detail__result__item__inner">
              <div class="politician-detail__result__item__topic">Alle Themen<span> im Vergleich</span></div>
              <div class="politician-detail__result__item__behavior-politician">
                <div class="politician-detail__result__item__behavior-politician__head">
                  <div class="politician-detail__result__item__behavior-politician__image" v-if="attendance.profile_image">
                    <img :src="attendance.profile_image" :alt="'Portraitfoto von ' + attendance.first_name + ' ' + attendance.last_name">
                  </div>
                  <div class="politician-detail__result__item__behavior-politician__name">
                    {{ attendance.first_name }} {{ attendance.last_name }}
                  </div>
                </div>
              </div>
              <div class="politician-detail__result__item__behavior-user">Sie</div>
            </div>
          </div>

          <div class="politician-detail__result__page">
            <PoliticianDetailResultItem
                v-for="resultListItem in result_list_items.slice(0, 10)"
                v-bind:key="resultListItem.id"
                :number="resultListItem.number"
                :id="resultListItem.id"
                :topic="resultListItem.topic"
                :userRating="resultListItem.userRating"
                :attendanceRating="resultListItem.attendanceRating"
                :attendanceReason="resultListItem.attendanceReason"
                :attendanceID="resultListItem.attendanceID"
            />
          </div>
          <div class="politician-detail__result__page" v-if="getAssumptionsCount() > 10">
            <PoliticianDetailResultItem
                v-for="resultListItem in result_list_items.slice(10, 20)"
                v-bind:key="resultListItem.id"
                :number="resultListItem.number"
                :id="resultListItem.id"
                :topic="resultListItem.topic"
                :userRating="resultListItem.userRating"
                :attendanceRating="resultListItem.attendanceRating"
                :attendanceReason="resultListItem.attendanceReason"
                :attendanceID="resultListItem.attendanceID"
            />
          </div>
          <div class="politician-detail__result__page" v-if="getAssumptionsCount() > 20">
            <PoliticianDetailResultItem
                v-for="resultListItem in result_list_items.slice(20, 30)"
                v-bind:key="resultListItem.id"
                :number="resultListItem.number"
                :id="resultListItem.id"
                :topic="resultListItem.topic"
                :userRating="resultListItem.userRating"
                :attendanceRating="resultListItem.attendanceRating"
                :attendanceReason="resultListItem.attendanceReason"
                :attendanceID="resultListItem.attendanceID"
            />
          </div>

          <div class="politician-detail__result__pagination">
            <div class="politician-detail__result__pagination__prev is-hidden" @click="resultPaginationGoTo((activeResultPage - 1))">Vorherige Seite</div>
            <div class="politician-detail__result__pagination__bullets">
              <span class="politician-detail__result__pagination__bullets__item is-active" @click="resultPaginationGoTo(1)"><span class="politician-detail__result__pagination__bullets__item__inner"></span></span>
              <span class="politician-detail__result__pagination__bullets__item" @click="resultPaginationGoTo(2)" v-if="getAssumptionsCount() > 10"><span class="politician-detail__result__pagination__bullets__item__inner"></span></span>
              <span class="politician-detail__result__pagination__bullets__item" @click="resultPaginationGoTo(3)" v-if="getAssumptionsCount() > 20"><span class="politician-detail__result__pagination__bullets__item__inner"></span></span>
            </div>
            <div class="politician-detail__result__pagination__next" @click="resultPaginationGoTo((activeResultPage + 1))">Nächste Seite</div>
          </div>
        </div>
      </div>

      <div class="politician-detail__footer">
        <ShareLinks :parliament_route_value="parliament.route_parameter_value" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useKCStore } from '@/app/stores/kc';
import ShareLinks from "@/components/result-components/ShareLinks";
import {Parliaments} from "@/app/parliaments";
import PoliticianDetailResultItem from "@/components/result-components/PoliticianDetailResultItem";

let parliaments = new Parliaments();

export default {
  name: 'CandidateDetail',
  components: {
    PoliticianDetailResultItem,
    ShareLinks
  },
  data() {
    return {
      activeResultPage: 1
    }
  },
  methods: {
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.add('header--small');
    },
    getProfileLink(link) {
      return this.addTrackingParametersToLink(link);
    },
    resultPaginationGoTo(index) {
      let pageContainer = document.querySelector('.politician-detail__result');
      let prevButton = document.querySelector('.politician-detail__result__pagination__prev');
      let nextButton = document.querySelector('.politician-detail__result__pagination__next');
      let pageCount = document.querySelectorAll('.politician-detail__result__page').length;

      pageContainer.setAttribute('data-active-result-page', index);
      this.activeResultPage = index;

      if (pageCount > index) {
        nextButton.classList.remove("is-hidden");
        prevButton.classList.add("is-hidden");
      } else {
        nextButton.classList.add("is-hidden");
        prevButton.classList.remove("is-hidden");
      }
      if (index <= pageCount) {
        nextButton.classList.remove("is-hidden");
      }
      if (index === 1) {
        prevButton.classList.add("is-hidden");
      }
      if (index >= pageCount) {
        nextButton.classList.add("is-hidden");
      }
      if (index > 1) {
        prevButton.classList.remove("is-hidden");
      }
    },
    hasHistory() { return window.history.length > 2 },
    backLinkBehavior(event) {
      let $component = this
      event.preventDefault();
      event.target.classList.add('is-loading');

      setTimeout(function(){
        $component.hasHistory ? $component.$router.go(-1) : $component.$router.push(this.back_link)
      }, 20);
    }
  },
  computed: {
    ...mapState(useKCStore, [
      'getAssumptionsCount',
      'addTrackingParametersToLink'
    ]),
    politicianId() {
     return parseInt(this.$route.params.politician_id);
    },
    parliament() {
      return parliaments.getParliamentByRouteValue(this.$route.params.parliament_route_value);
    },
    parliament_election_year() {
      const store = useKCStore();
      return store.getElectionYear();
    },
    attendance() {
      const store = useKCStore();
      return store.getCCAttendancyByPoliticianId(this.politicianId);
    },
    result_list_items() {
      const store = useKCStore();
      let resultListItems = [];
      let assumptions = store.getAllAssumptions();
      let positionsUser = store.getAllUserPositions();
      let politicianId = this.politicianId;
      let positionsPolitician = store.getPositionsByPoliticianId(this.politicianId);

      assumptions.forEach(function(element, index) {
        resultListItems.push({
          number: element.number,
          id: element.id,
          topic: element.topic === null ? element.text : element.topic,
          attendanceRating: positionsPolitician[index].rating,
          attendanceReason: positionsPolitician[index].reason,
          userRating: positionsUser[index].rating,
          attendanceID: politicianId
        })
      }, positionsUser, positionsPolitician);

      return resultListItems;
    },
    back_link() {
      return '/' + this.$route.params.parliament_route_value + '/ihr-ergebnis';
    },
    match_score() {
      const store = useKCStore();
      let matchAttendance = store.getCCAttendancyByPoliticianId(this.politicianId);
      if (matchAttendance) {
        return matchAttendance.matching_value;
      } else {
        return false;
      }
    }
  },
  mounted:function(){
    this.set_header_variant();
  },
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/politician_detail";

</style>