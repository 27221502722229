<template>
  <div class="share-links">
    <p class="text-center">Teilen Sie den <img alt="Kandidierendencheck" src="@/assets/kc_logo_claim.svg" width="190" height="14"> mit Ihren Freund:innen</p>
    <ul class="share-links__list">
      <li class="share-links__list__item">
        <a :href="facebookLink" class="share-links__list__link share-links__list__link--facebook" target="_blank">
          <span class="svg-icon svg-icon--medium">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-facebook fa-w-16"><path fill="currentColor" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" class=""></path></svg>
          </span>
        </a>
      </li>
      <li class="share-links__list__item">
        <a :href="twitterLink" class="share-links__list__link share-links__list__link--twitter" target="_blank">
          <span class="svg-icon svg-icon--medium">
            <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-twitter fa-w-16"><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" class=""></path></svg>
          </span>
        </a>
      </li>
      <li class="share-links__list__item">
        <a :href="mailLink" class="share-links__list__link share-links__list__link--mail">
          <span class="svg-icon svg-icon--medium">
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-envelope fa-w-16"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { Parliaments } from "@/app/parliaments";
import {useKCStore} from "@/app/stores/kc";

let parliaments = new Parliaments();
const appURL = window.location.origin;

export default {
  name: 'ShareLinks',
  props: ['parliament_route_value'],
  data() {
    return {
      facebookLink: 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(this.get_kc_link()),
      twitterLink: 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(this.get_kc_link()) + '&via=a_watch&text=' + this.get_share_text(),
      mailLink: 'mailto:?subject=Ich empfehle Dir den Kandidierendencheck&body=' + this.get_share_text() + ' Dies ist der Link:' + encodeURIComponent(this.get_kc_link())
    }
  },
  methods: {
    get_kc_link() {
      return appURL + '/' + this.parliament_route_value;
    },
    get_share_text() {
      return 'Wer tickt wie ich? Mit dem KandidierendenCheck den/die passende Direktkandidat:in zur ' + this.get_election_label() +' finden.';
    },
    get_election_label() {
      const store = useKCStore();
      let parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value);
      return parliament.label +' ' + store.getElectionYear();
    }
  }
}
</script>

<style lang="scss">
@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/share_links";
</style>