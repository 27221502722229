<template>
  <div class="assumption-detail">
    <div class="assumption-detail__shadow" @click="closeAssumptionDetail"></div>
    <div class="assumption-detail__modal">
      <div class="assumption-detail__sidebar">
        <div class="assumption-detail__sidebar__pagination" v-if="showPagination">
          <div class="assumption-detail__sidebar__pagination__prev" @click="showPrevAssumption">
            <i class="aw-icon-chevron-right"></i>
          </div>
          <div class="assumption-detail__sidebar__indicator">
            <strong>{{ assumption.number }}</strong>/{{ assumptionCount }}
          </div>
          <div class="assumption-detail__sidebar__pagination__next" @click="showNextAssumption">
            <i class="aw-icon-chevron-right"></i>
          </div>
        </div>

        <div class="assumption-detail__sidebar__quote">
          <div class="assumption-detail__sidebar__quote__topic">{{ assumption.topic }}</div>
          <div class="assumption-detail__sidebar__quote__inner">
            {{ assumption.text }}
          </div>
        </div>

      </div>
      <div class="assumption-detail__content">
        <div class="assumption-detail__content__attendance">
          <div class="assumption-detail__content__attendance__image">
            <img :src="attendance.profile_image" :alt="'Portraitfoto von ' + attendance.first_name + ' ' + attendance.last_name">
          </div>
          <div class="assumption-detail__content__attendance__info">
            <div class="assumption-detail__content__attendance__info__name">{{ attendance.first_name }} {{ attendance.last_name }}</div>
            <div class="party" v-if="attendance.party">
              <div class="party__indicator" :style="{ background: attendance.party_color }"></div>
              <div class="party__name">{{ attendance.party }}</div>
            </div>
          </div>
        </div>
        <div class="assumption-detail__content__rating" v-if="attendance_rating[0].rating">
          <span v-if="attendance_rating[0].rating === 'agree'">
            <i class="aw-icon-check"></i> Ich stimme zu
          </span>
          <span v-if="attendance_rating[0].rating === 'neutral'">
            <i class="aw-icon-circle-o"></i> Ich sehe es neutral
          </span>
          <span v-if="attendance_rating[0].rating === 'disagree'">
            <i class="aw-icon-close"></i> Ich stimme dagegen
          </span>
        </div>
        <div class="assumption-detail__content__reason assumption-detail__content__reason--placeholder" v-if="!attendance_rating[0].reason">
          <p>{{ attendance.first_name }} {{ attendance.last_name }} hat zu dieser Position keine Begründung angegeben.</p>
        </div>
        <div class="assumption-detail__content__reason assumption-detail__content__reason--placeholder" v-if="attendance_rating[0].reason && attendance_rating[0].status === 'problematic'">
          <p>Diese Begründung wird von uns nicht veröffentlicht, da sie gegen unseren Moderations-Kodex verstößt. {{ attendance.first_name }} {{ attendance.last_name }} wurde darüber informiert und gebeten, den Text enstprechend zu überarbeiten.</p>
        </div>
        <div class="assumption-detail__content__reason" v-if="attendance_rating[0].reason && attendance_rating[0].status !== 'problematic'" v-html="attendance_rating[0].reason"></div>
        <div class="assumption-detail__content__question">
          <p>Sie haben dazu noch Fragen? Befragen Sie {{ attendance.first_name }} {{ attendance.last_name }} auf <img alt="Logo von abgeordnetenwatch.de" src="@/assets/aw_logo.svg" width="181" height="18"></p>
          <a :href="getQuestionLink(attendance.profile_link)" class="button button--large" target="_blank">Jetzt Frage stellen</a>
        </div>
      </div>
      <div class="assumption-detail__close" @click="closeAssumptionDetail"><i class="aw-icon-close aw-icon--large"></i></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useKCStore } from '@/app/stores/kc';

export default {
  name: 'AssumptionDetail',
  props: ['number', 'id', 'topic', 'attendanceRating', 'attendanceReason', 'attendanceID', 'visible', 'parliament_route_value'],
  data(){
    const store = useKCStore();
    return {
      assumption: store.getAssumption(this.number),
      assumptionCount: store.getAssumptionsCount(),
      attendance: store.getCCAttendancyByPoliticianId(this.attendanceID),
      attendance_rating: store.getPositionsByPoliticianId(this.attendanceID).filter(element => element.assumption_nr === this.number)
    }
  },
  computed: {
    ...mapState(useKCStore,[
      'addTrackingParametersToLink'
    ]),
    showPagination() {
      if (this.$parent.$options.name === 'PoliticianDetailResultItem') {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.setNarrowModal();
    window.onresize = this.setNarrowModal;
  },
  methods: {
    closeAssumptionDetail() {
      let body = document.querySelector('html');
      body.classList.remove('is-open-modal');
      this.$parent.showAssumptionModal = false;
    },
    setNarrowModal() {
      let modalWrapper = document.querySelector('.assumption-detail');
      let modalWrapperPadding = window.getComputedStyle(modalWrapper, null).getPropertyValue('padding-top').slice(0, -2);
      let modalShadow = document.querySelector('.assumption-detail__shadow');
      let modalHeight = document.querySelector('.assumption-detail__modal').offsetHeight;
      let windowHeight = window.innerHeight;

      if (modalHeight > windowHeight) {
        modalWrapper.classList.add("assumption-detail--narrow");
        modalShadow.style.height = ((modalWrapperPadding * 2) + modalHeight) + 'px';
      } else {
        modalWrapper.classList.remove("assumption-detail--narrow");
        modalShadow.style.height = 'auto';
      }
    },
    showPrevAssumption() {
      const store = useKCStore();
      let prevAssumptionNumber = this.assumption.number - 1;
      if (prevAssumptionNumber < 1) {
        this.assumption = store.getAssumption(this.assumptionCount);
        this.attendance_rating = store.getPositionsByPoliticianId(this.attendanceID).filter(element => element.assumption_nr === this.assumptionCount);
      } else {
        this.assumption = store.getAssumption(prevAssumptionNumber);
        this.attendance_rating = store.getPositionsByPoliticianId(this.attendanceID).filter(element => element.assumption_nr === this.assumption.number);
      }
    },
    showNextAssumption() {
      const store = useKCStore();
      let nextAssumptionNumber = this.assumption.number + 1;

      if (nextAssumptionNumber > this.assumptionCount) {
        this.assumption = store.getAssumption(1);
        this.attendance_rating = store.getPositionsByPoliticianId(this.attendanceID).filter(element => element.assumption_nr === 1);
      } else {
        this.assumption = store.getAssumption(nextAssumptionNumber);
        this.attendance_rating = store.getPositionsByPoliticianId(this.attendanceID).filter(element => element.assumption_nr === this.assumption.number);
      }
    },
    getQuestionLink(profile_link) {
      let link = this.addTrackingParametersToLink(profile_link);
      link = link + '&open_form=true';
      link = link + '&position='+ this.attendance_rating[0].position_id;
      return link;
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/assumption_detail";

</style>