import { createRouter, createWebHistory } from "vue-router"
import LandingpageParliament from '../components/pages/LandingpageParliament';
import AreaCodeSearch from '../components/pages/AreaCodeSearch';
import Frontpage from '../components/pages/Frontpage';
import NotFound from '../components/errors/NotFound';
import ChooseDescriptors from '../components/pages/ChooseDescriptors';
import AssumptionRating from '../components/pages/AssumptionRating';
import YourResult from '../components/pages/YourResult';
import CandidateDetail from '../components/pages/CandidateDetail';
import { useKCStore } from "./stores/kc";
import { Parliaments } from './parliaments';

const routes = [
  {
    path: '/',
    component: Frontpage,
    name: 'Frontpage'
  },
  {
    path: '/:parliament_route_value',
    name: 'LandingpageParliament',
    component: LandingpageParliament,
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null) {
        next({name: 'NotFound'})
      }
      else {
        next();
      }
    }
  },
  {
    path: '/:parliament_route_value/plz-suche',
    component: AreaCodeSearch,
    name: 'AreaCodeSearch',
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null) {
        next({name: 'NotFound'})
      }
      else {
        const store = useKCStore();
        // check if "played_candidacy_check" is set
        let played_candidacy_check = store.getPlayedCandidacyCheck(),
            assumptions_counter = store.getAssumptionsCount(),
            user_positions_counter = store.getUserPositionsCount();
        if (played_candidacy_check === null || typeof played_candidacy_check === 'undefined') {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        // check if "played_candidacy_check" is set to the current parliament
        else if (played_candidacy_check.parliament_id !== parliament_id) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        // check that all assumptions has been rated by the user
        else if(user_positions_counter < assumptions_counter) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        else {
          next()
        }
      }
    }
  },
  {
    path: '/:parliament_route_value/wahlkreis-auswahl',
    component: ChooseDescriptors,
    name: 'ChooseDescriptors',
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null) {
        next({name: 'NotFound'})
      }
      else {
        const store = useKCStore();
        // check if "played_candidacy_check" is set
        let played_candidacy_check = store.getPlayedCandidacyCheck();
        if (played_candidacy_check === null || typeof played_candidacy_check === 'undefined') {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        // check if "played_candidacy_check" is set to the current parliament
        else if (played_candidacy_check.parliament_id !== parliament_id) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        // check if "played_candidacy_check.descriptors" is set correctly
        let checkConstituency = store.checkConstituencyDescriptors();
        if (checkConstituency === 'descriptors') {
          next();
        }
        else {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
      }
    }
  },
  {
    path: '/:parliament_route_value/these/:assumption_nr',
    component: AssumptionRating,
    name: 'AssumptionRating',
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null) {
        next({name: 'NotFound'})
      }
      else {
        const store = useKCStore();
        // check if "played_candidacy_check" is set
        let played_candidacy_check = store.getPlayedCandidacyCheck();
        if (played_candidacy_check === null || typeof played_candidacy_check === 'undefined') {
          next({name: 'LandingpageParliament', params: {
            parliament_route_value: to.params.parliament_route_value,

          }})
        }
        // check if "played_candidacy_check" is set to the current parliament
        if (played_candidacy_check.parliament_id !== parliament_id) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        next();
      }
    }
  },
  {
    path: '/:parliament_route_value/ihr-ergebnis',
    component: YourResult,
    name: 'YourResult',
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null || typeof parliament_id === 'undefined') {
        next({name: 'NotFound'})
      }
      else {
        const store = useKCStore();
        // check if "played_candidacy_check" is set
        let played_candidacy_check = store.getPlayedCandidacyCheck();
        if (played_candidacy_check === null || typeof played_candidacy_check === 'undefined') {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        // check if "played_candidacy_check" is set to the current parliament
        if (played_candidacy_check.parliament_id !== parliament_id) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        // check if there are user positions for all assumptions
        let assumptions_count = store.getAssumptionsCount(),
          user_positions_count = store.getUserPositionsCount();
        if (assumptions_count !== user_positions_count) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        next();
      }
    }
  },
  {
    path: '/:parliament_route_value/ergebnis-details/kandidat/:politician_id',
    component: CandidateDetail,
    name: 'CandidateDetail',
    props: true,
    beforeEnter: (to, from, next) => {
      let parliaments = new Parliaments(),
        parliament_id = parliaments.getParliamentIdByRouteValue(to.params.parliament_route_value);
      if (parliament_id === null || typeof parliament_id === 'undefined') {
        next({name: 'NotFound'})
      }
      else {
        const store = useKCStore();
        // check if "played_candidacy_check" is set
        let played_candidacy_check = store.getPlayedCandidacyCheck();
        if (played_candidacy_check === null || typeof played_candidacy_check === 'undefined') {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }
        // check if "played_candidacy_check" is set to the current parliament
        if (played_candidacy_check.parliament_id !== parliament_id) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        // check if there are user positions for all assumptions
        let assumptions_count = store.getAssumptionsCount(),
          user_positions_count = store.getUserPositionsCount();
        if (assumptions_count !== user_positions_count) {
          next({name: 'LandingpageParliament', params: { parliament_route_value: to.params.parliament_route_value }})
        }

        // check if there is a politician with that id
        let attendance = store.getCCAttendancyByPoliticianId(parseInt(to.params.politician_id));
        if (attendance === null || typeof attendance === 'undefined') {
          next({name: 'NotFound'})
        }

        next();
      }
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    name: 'NotFound'
  }
]

const router = createRouter({
  history : createWebHistory(),
  routes : routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

export default router;
