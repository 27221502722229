<template>
  <div class="l-container l-container--small text-center">
    <div class="error-page">
      <h1>Etwas ist schief gelaufen</h1>
      <p class="subtitle">Diese Seite wurde leider nicht gefunden.</p>
      <a href="/" class="button button--large">Zur Startseite</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>


<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/error-page";

</style>