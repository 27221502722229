const SORTING_MATCHING_DESC = 'matching_desc';

export default {
  SORTING_ALPHABETICAL_ASC: 'alphabetical_asc',
  SORTING_ALPHABETICAL_DESC: 'alphabetical_desc',
  SORTING_MATCHING_DESC: SORTING_MATCHING_DESC,
  SORTING_MATCHING_ASC: 'matching_asc',
  SORTING_DEFAULT: SORTING_MATCHING_DESC,
  SORTING_DEFAULT_LABEL: 'Übereinstimmung ↓',
  FILTER_BY_PARTY_DEFAULT: 'all',
  FILTER_BY_PARTY_DEFAULT_LABEL: 'Alle Parteien',
  RATING_AGREE: 'agree',
  RATING_DISAGREE: 'disagree',
  RATING_NEUTRAL: 'neutral'
}
