<template>
  <div class="podium">
    <h1>Hätten Sie gedacht, dass Sie am meisten übereinstimmen mit &hellip;?</h1>

    <div class="politicians politicians--podium">
      <Politician
          v-for="item in podiumCreate" :key="item.politician_id"
          v-bind:profileImage="item.profile_image"
          :politicianID="item.politician_id"
          v-bind:firstName="item.first_name"
          v-bind:lastName="item.last_name"
          v-bind:partyColor="item.party_color"
          v-bind:party="item.party"
          v-bind:percantage="item.matching_value"
          v-bind:candidacyID="item.id"
          v-bind:parliament_route_value="parliament_route_value"
          v-bind:podiumstep="item.podiumstep"
          variant="podium"
          linkToDetail="true"
      />
    </div>

  </div>
</template>

<script>
import Politician from "@/components/Politician";
import kc_settings from '@/app/kc_settings';
import {useKCStore} from "@/app/stores/kc";

export default {
  name: 'WinnersPodium',
  props: ['parliament_route_value'],
  components: {
    Politician
  },
  computed: {
    podiumCreate() {
      const store = useKCStore();
      let podium = [];

      let firstAttendance = store.getCCAttendances(kc_settings.FILTER_BY_PARTY_DEFAULT, kc_settings.SORTING_MATCHING_DESC)[0];
      if (firstAttendance) {
        podium.push(firstAttendance);
      }

      let secondAttendance = store.getCCAttendances(kc_settings.FILTER_BY_PARTY_DEFAULT, kc_settings.SORTING_MATCHING_DESC)[1];
      if (secondAttendance) {
        podium.push(secondAttendance);
      }

      let thirdAttendance = store.getCCAttendances(kc_settings.FILTER_BY_PARTY_DEFAULT, kc_settings.SORTING_MATCHING_DESC)[2];
      if (thirdAttendance) {
        podium.push(thirdAttendance);
      }

      // set the podium step (1, 2 or 3) for the visual styling
      if (firstAttendance) {
        firstAttendance.podiumstep = 1;

        if (secondAttendance && firstAttendance.matching_value === secondAttendance.matching_value) {
          secondAttendance.podiumstep = firstAttendance.podiumstep;
        }
        else if (secondAttendance) {
          secondAttendance.podiumstep = firstAttendance.podiumstep + 1;
        }

        if (secondAttendance && thirdAttendance &&
            secondAttendance.matching_value === thirdAttendance.matching_value) {
          thirdAttendance.podiumstep = secondAttendance.podiumstep;
        }
        else if (secondAttendance && thirdAttendance) {
          thirdAttendance.podiumstep = secondAttendance.podiumstep +1;
        }
      }
      return podium;
    }
  }
}
</script>
