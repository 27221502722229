<template>
  <div class="result-email-button">
    <span class="button button--beige button--large" @click.prevent="send_to_email_form()">
      <span class="button__icon icon-svg">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-envelope fa-w-16"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
      </span>
      <span class="button__text">Ergebnis als E-Mail zuschicken</span>
    </span>
  </div>
</template>

<script>
import kc_settings from '@/app/kc_settings';
import {useKCStore} from "@/app/stores/kc";
const appURL = window.location.origin;

export default {
  name: 'ResultEmailButton',
  props: ['parliament_route_value'],
  methods: {
    get_kc_link() {
      return appURL + '/' + this.parliament_route_value;
    },
    send_to_email_form() {
      const store = useKCStore();
      let form = document.createElement('form');
      let actionLink = process.env.VUE_APP_AW_CC_API_BASEPATH + 'cc/email-results';

      actionLink = store.addTrackingParametersToLink(actionLink);
      form.action = actionLink;
      form.method = 'POST';
      form.target = '_blank';

      let candidacy_check = document.createElement('input');
      candidacy_check.type = 'hidden';
      candidacy_check.name = 'candidacy_check_id';
      candidacy_check.value = store.getPlayedCandidacyCheckId();
      form.appendChild(candidacy_check);

      let parliament_period = document.createElement('input');
      parliament_period.type = 'hidden';
      parliament_period.name = 'parliament_period_id';
      parliament_period.value = store.getParliamentPeriodId();
      form.appendChild(parliament_period);

      let constituency = document.createElement('input');
      constituency.type = 'hidden';
      constituency.name = 'constituency_id';
      constituency.value = store.getConstituencyId();
      form.appendChild(constituency);

      let results = store.getCCAttendances(kc_settings.FILTER_BY_PARTY_DEFAULT, kc_settings.SORTING_MATCHING_DESC);
      let maxResults = 3;
      for (let i = 0; i < maxResults; i++) {
        if (typeof results[i] !== 'undefined') {
          let result_element_candidacy = document.createElement('input');
          result_element_candidacy.type = 'hidden';
          result_element_candidacy.name = 'results['+i+'][attendance_id]';
          result_element_candidacy.value = results[i]['id'];
          form.appendChild(result_element_candidacy);

          let result_element_matching = document.createElement('input');
          result_element_matching.type = 'hidden';
          result_element_matching.name = 'results['+i+'][matching_value]';
          result_element_matching.value = results[i]['matching_value'];
          form.appendChild(result_element_matching);
        }
      }

      let link_kc = document.createElement('input');
      link_kc.type = 'hidden';
      link_kc.name = 'kc_link';
      link_kc.value = this.get_kc_link();
      form.appendChild(link_kc);

      let tracking_params = store.getTrackingParameters();

      Object.keys(tracking_params).forEach(function(key) {
        let tracking_param_field = document.createElement('input');
        tracking_param_field.type = 'hidden';
        tracking_param_field.name = key;
        tracking_param_field.value = tracking_params[key];
        form.appendChild(tracking_param_field);
      });

      document.body.appendChild(form);
      form.submit();
      this.$matomo?.trackEvent('Result', `Send result as email`, 'Send as email button');
    }
  }
}
</script>
