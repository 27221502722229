<template>
  <div class="politician-overview">
    <div class="politician-overview__intro">
      <h2>Alle {{ attendanceCounter }} Kandidierenden aus Ihrem {{ parliamentConstituencyTerm }}, die am Check teilgenommen haben</h2>


      <div class="politician-overview__intro__form form">
        <div class="politician-overview__intro__form__item">
          <div class="form__select politician-overview__intro__form__select">
            <v-select
                :options="partiesOptions"
                id="politicianOverviewParty"
                @option:selected="filterByPartyEventHandler($event)"
                :searchable="false"
                :clearable="false"
                :modelValue="selected_party"
            ></v-select>
          </div>
        </div>
        <div class="politician-overview__intro__form__item">
          <div class="form__select politician-overview__intro__form__select">
            <v-select
                :options="sortingOptions"
                id="politicianOverviewOrder"
                @option:selected="sortEventHandler($event)"
                :searchable="false"
                :clearable="false"
                v-model="selected_sorting"
            ></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="politician-overview__swiper">
      <PoliticianSwiper
          swiperRef="politicianSwiperOverview"
          variant="result-overview"
          :politicianList="getAttendances()"
          :parliament_route_value="parliament_route_value"
          :linkToDetail="true"
      />
    </div>
  </div>
</template>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/politician_overview";

</style>
<script>
import PoliticianSwiper from "@/components/result-components/PoliticianSwiper";
import kc_settings from '@/app/kc_settings';
import storageAvailable from "storage-available";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import {useKCStore} from "@/app/stores/kc";
import {Parliaments} from "@/app/parliaments";

export default {
  components: {
    vSelect,
    PoliticianSwiper
  },
  props: ['parliament_route_value'],
  data() {
    return {
      'filter_by_party': null,
      'sorting': null,
      'selected_party': kc_settings.FILTER_BY_PARTY_DEFAULT_LABEL,
      'selected_sorting': kc_settings.SORTING_DEFAULT_LABEL,
      'sorting_alphabetical_asc': kc_settings.SORTING_ALPHABETICAL_ASC,
      'sorting_alphabetical_desc': kc_settings.SORTING_ALPHABETICAL_DESC,
      'sorting_matching_desc': kc_settings.SORTING_MATCHING_DESC,
      'sorting_matching_asc': kc_settings.SORTING_MATCHING_ASC
    }
  },
  mounted() {
    this.loadViewOptions();
  },
  computed: {
    partiesOptions() {
      let attendances = this.getAttendances(true),
          parties = [];

      if (attendances) {
        attendances.forEach(function (singleAttendance) {

          let existing_party = parties.find(element => element.id === singleAttendance.party_id);
          if (!existing_party) {
            parties.push({
              "id": singleAttendance.party_id,
              "label": singleAttendance.party
            })
          }
        })
      }
      parties.sort(function(a,b) {
        if(a.label < b.label) { return -1; }
        if(a.label > b.label) { return 1; }
        return 0;
      });
      parties.unshift({
        "id": "all",
        "label": "Alle Parteien"
      })
      return parties;
    },
    sortingOptions() {
      let sortings = [];
      sortings.push({"label": "Alphabetisch ↑", "id": this.sorting_alphabetical_asc});
      sortings.push({"label": "Alphabetisch ↓", "id": this.sorting_alphabetical_desc});
      sortings.push({"label": "Übereinstimmung ↑", "id": this.sorting_matching_asc});
      sortings.push({"label": "Übereinstimmung ↓", "id": this.sorting_matching_desc});
      return sortings;
    },
    attendanceCounter() {
      let attendances = this.getAttendances(true);
      return attendances.length;
    },
    parliamentConstituencyTerm() {
      const parliaments = new Parliaments();
      const parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value);
      return parliament.constituency_term;
    },
  },
  methods: {
    filterByPartyEventHandler($selectedOption) {
      if (storageAvailable('localStorage')) {
        localStorage.setItem('politicianOverviewFilterValue', $selectedOption.id);
        localStorage.setItem('politicianOverviewFilterLabel', $selectedOption.label);
      }
      this.filter_by_party = $selectedOption.id;
      this.selected_party = $selectedOption.label;
      this.getAttendances();
      this.$matomo?.trackEvent('Result', 'Filter politicians by party', 'Politician overview');
    },
    sortEventHandler($selectedOption) {
      if (storageAvailable('localStorage')) {
        localStorage.setItem('politicianOverviewSortingValue', $selectedOption.id);
        localStorage.setItem('politicianOverviewSortingLabel', $selectedOption.label);
      }
      this.sorting = $selectedOption.id;
      this.selected_sorting = $selectedOption.label;
      this.getAttendances();
      this.$matomo?.trackEvent('Result', `Sort politicians (${this.selected_sorting})`, 'Politician overview');
    },
    getAttendances(not_filtered = false) {
      let filter_by_party = this.filter_by_party,
          sorting_by = this.sorting;
      if (not_filtered || filter_by_party === null) {
        filter_by_party = kc_settings.FILTER_BY_PARTY_DEFAULT;
      }
      if (sorting_by === null) {
        sorting_by = kc_settings.SORTING_DEFAULT;
      }

      const store = useKCStore();
      return store.getCCAttendances(filter_by_party, sorting_by);
    },
    loadViewOptions() {
      if (storageAvailable('localStorage')) {
        if (localStorage.politicianOverviewFilterValue) {
          this.filter_by_party = localStorage.politicianOverviewFilterValue;
        }
        if (localStorage.politicianOverviewFilterLabel) {
          this.selected_party = localStorage.politicianOverviewFilterLabel;
        }
        if (localStorage.politicianOverviewSortingValue) {
          this.sorting = localStorage.politicianOverviewSortingValue;
        }
        if (localStorage.politicianOverviewSortingLabel) {
          this.selected_sorting = localStorage.politicianOverviewSortingLabel;
        }
      }
    }
  }
}
</script>
