<template>
  <h1 class="parliament-title" v-if="parliamentLabel">
      <span class="parliament-title__icon" v-if="parliamentIcon">
       <i :class="'aw-icon-' + parliamentIcon"></i>
      </span>
    <span class="parliament-title__label" v-if="parliamentLabel && parliamentYear">
        {{ parliamentLabel }} {{ parliamentYear }}
      </span>
  </h1>
</template>

<script>
export default {
  name: 'ParliamentTitle',
  props: ['parliamentLabel', 'parliamentYear', 'parliamentIcon']
}
</script>

<style lang="scss">
@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/parliament_title";
</style>