<template>
  <div class="constituency-selection__item__inner">
    <h3 class="h4 constituency-selection__item__title">{{ descriptor }}</h3>
    <div class="constituency-selection__item__footer">
      <button class="button button--small" id="choose-constituency" :data-constituency-id="constituency_id" v-on:click="set_constituency">{{ parliamentConstituencyTerm }} auswählen</button>
    </div>
  </div>
</template>

<script>


import {useKCStore} from "@/app/stores/kc";
import {Parliaments} from "@/app/parliaments";

export default {
  name: 'Descriptor',
  props: ['descriptor', 'constituency_id', 'parliament_route_value'],
  computed: {
    parliamentConstituencyTerm() {
      const parliaments = new Parliaments();
      const parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value);
      return parliament.constituency_term;
    },
  },
  methods: {
    set_constituency(event) {
      const store = useKCStore();
      let constitutency_id = event.currentTarget.getAttribute('data-constituency-id');
      store.loadConstituency(constitutency_id).then(() => {
        let constituency = store.getConstituency();
        if (constituency !== null) {
          this.$router.push({ name: "YourResult",  params: { parliament_route_value: this.parliament_route_value }})
        }
        else {
          // @todo err message
        }
      })
    }
  }
}
</script>
