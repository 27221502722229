<template>
  <div class="l-container frontpage">
    <h1 class="visually-hidden">Kandidierenden-Check von abgeordnetenwatch.de</h1>
    <div v-if="active_candidacy_checks_counter">
      <div class="frontpage__selection">
        <div v-for="candidacy_check in active_candidacy_checks" :key="candidacy_check.parliament_id" class="frontpage__selection__item">
          <router-link :to="candidacy_check.parliament_path" class="frontpage__selection__item__link" target="_blank">
            <div class="frontpage__selection__item__label">
              <i :class="'aw-icon-' + candidacy_check.parliament_icon"></i>
              {{candidacy_check.parliament_label}}
            </div>
            <div class="frontpage__selection__item__cta">Kandidierenden-Check starten</div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-else-if="active_candidacy_checks_counter === 0">
      <p class="text-center">Leider finden zur Zeit keine Kandidierenden-Checks statt.</p>
    </div>
  </div>
</template>

<script>
import { Parliaments } from '@/app/parliaments';
import { useKCStore } from '@/app/stores/kc';

let parliaments = new Parliaments();

export default {
  name: 'Frontpage',
  created() {
    const store = useKCStore();
    store.loadActiveCandidacyChecks();
    // always reset played_candidacy_check
    this.played_candidacy_check = null;
  },
  computed: {
    active_candidacy_checks_counter() {
      const store = useKCStore();
      return store.getActiveCandidacyChecksCount();
    },
    active_candidacy_checks() {
      const store = useKCStore();
      const data = store.getActiveCandidacyChecks();

      for (let candidacy_check of data) {
        let single_parliament = parliaments.getParliamentById(candidacy_check.parliament_id);
        candidacy_check.parliament_label = single_parliament.label;
        candidacy_check.parliament_icon = single_parliament.icon;
        candidacy_check.parliament_path = '/' + single_parliament.route_parameter_value;
      }
      return data;
    }
  },
  mounted:function(){
    this.set_header_variant();
  },
  methods: {
    set_header_variant() {
      let header = document.querySelector('.header');
      header.classList.remove('header--small');
    }
  }
}
</script>

<style lang="scss">

@import "/src/scss/settings";
@import "/src/scss/tools";

@import "/src/scss/components/frontpage";

</style>
