<template>
  <div class="assumption-compare-tab__teaser">
    <div v-if="user_rating === rating_selected">
      <span class="assumption-compare-tab__teaser__label">Sie <br>stimmen mit</span>
      <span class="assumption-compare-tab__teaser__value">
      <span class="assumption-compare-tab__teaser__value__count">
        {{ count_positions }}/<small>{{ count_positions_total }}</small></span>
      <span class="assumption-compare-tab__teaser__value__label">Kandidierenden</span>
    </span>
      <span class="assumption-compare-tab__teaser__label">überein.</span>
    </div>
    <div v-else>
      <span class="assumption-compare-tab__teaser__label">Anders als Sie <br>stimmten</span>
      <span class="assumption-compare-tab__teaser__value">
      <span class="assumption-compare-tab__teaser__value__count">
        {{ count_positions }}/<small>{{ count_positions_total }}</small></span>
      <span class="assumption-compare-tab__teaser__value__label">Kandidierenden</span>
    </span>
      <span v-if="rating_selected === 'agree'">
          <span class="assumption-compare-tab__teaser__label">dafür.</span>
      </span>
      <span v-else-if="rating_selected === 'disagree'">
          <span class="assumption-compare-tab__teaser__label">dagegen.</span>
      </span>
      <span v-else-if="rating_selected === 'neutral'">
          <span class="assumption-compare-tab__teaser__label">neutral ab.</span>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AssumptionTabsTeaser',
  props: ['rating_selected', 'user_rating', 'count_positions', 'count_positions_total']
}
</script>