<template>
  <div class="website-teaser">
    <div class="website-teaser__image">
      <img alt="abgeordnetenwatch.de auf einem Tablet" title="abgoerdnetenwatch.de" src="@/assets/result/aw_tablet.png" width="306" height="162">
    </div>
    <div class="website-teaser__text">
      <h3>
        Bleiben Sie auf dem Laufenden zur {{ parliamentLabel }}.
        Transparent und unabhängig.
      </h3>
      <p>Auf unserem Wahlportal zur {{ parliamentLabel }} können Sie sich alle Kandidierenden ansehen, ihnen Fragen stellen, die Wahlprogramme durchblättern und zahlreiche Infos zur Wahl finden.</p>
      <a :href="getLink" class="button button--large" target="_blank">Zu abgeordnetenwatch.de</a>
    </div>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {useKCStore} from "@/app/stores/kc";
import {Parliaments} from '@/app/parliaments';

let parliaments = new Parliaments();

export default {
  name: 'WebsiteTeaser',
  props: ['parliamentLabel', 'parliament_route_value'],
  computed: {
    ...mapState(useKCStore,[
      'getParliamentLandingpageLink'
    ]),
    getLink() {
      const store = useKCStore();
      let link = this.getParliamentLandingpageLink(),
          parliament = parliaments.getParliamentByRouteValue(this.parliament_route_value),
          trackingParameters = store.getTrackingParameters(parliament);
      if (trackingParameters) {
        link = link + '?pk_campaign=' + trackingParameters.pk_campaign + '&pk_kwd=' + trackingParameters.pk_kwd
      }
      return link;
    }
  }
}
</script>